

// import React, { useState } from 'react';
// import component from '../assets/component.png';
// import prototype from '../assets/prototype.png';
// import manufacturing from '../assets/manufacturing.png';
// import packaging from '../assets/package.png';
// import shipment from '../assets/tracking.png';
// import rawmaterials from '../assets/raw-materials.png'; 
// import './Procurement.css';
// import Heading from './Headings/Heading';


// const Procurement = () => {
//   const [hoveredImage, setHoveredImage] = useState(null);

//   const content = {
//     component: "Truly inspiring designs",
//     prototype: "Faster and Precise Replica’s",
//     manufacturing: "Find most suitable and affordable method",
//     packaging: "Well designed and perfect packing is our responsibility",
//     shipment: "Our integrated SCM will help you give a best overall experience",
//     rawmaterials: "Buy tested and certified raw material ",
//   };

//   const images = [
//     { src: rawmaterials, alt: 'Raw Materials', id: 'rawmaterials' },
//     { src: component, alt: 'Component', id: 'component' },
//     { src: prototype, alt: 'Prototype', id: 'prototype' },
//     { src: manufacturing, alt: 'Manufacturing', id: 'manufacturing' },
//     { src: packaging, alt: 'Packaging', id: 'packaging' },
//     { src: shipment, alt: 'Shipment', id: 'shipment' },
//   ];

//   return (
//     <div className='Procurement-container'>
//       <Heading as="h1" className='ProcurementCycle-heading'>Procurement Cycle</Heading><br/><br/><br/>
//     <div className="circle-container">
//       <div className="center-image">
//         {hoveredImage ? (
//           <div className="content">{content[hoveredImage]}</div>
//         ) : (
//           <h3 className='Product-cycle-heading'>Product Cycle</h3>
//         )}
//       </div>
//       <div className="surrounding-images">
//         {images.map(image => (
//           <img
//             key={image.id}
//             src={image.src}
//             alt={image.alt}
//             className="surrounding-image"
//             onMouseEnter={() => setHoveredImage(image.id)}
//             onMouseLeave={() => setHoveredImage(null)}
//           />
//         ))}
//       </div>
//     </div>
//     </div>
//   );
// };

// export default Procurement;






// import React, { useState } from 'react';
// import component from '../assets/component.png';
// import prototype from '../assets/prototype.png';
// import manufacturing from '../assets/manufacturing.png';
// import packaging from '../assets/package.png';
// import shipment from '../assets/tracking.png';
// import rawmaterials from '../assets/raw-materials.png'; 
// import './Procurement.css';

// const Procurement = () => {
//   const [hoveredImage, setHoveredImage] = useState(null);

//   const content = {
//     component: "Truly inspiring designs",
//     prototype: "Faster and Precise Replica’s",
//     manufacturing: "Find most suitable and affordable method",
//     packaging: "Well designed and perfect packing is our responsibility",
//     shipment: "Our integrated SCM will help you give a best overall experience",
//     rawmaterials: "Buy tested and certified raw material ",
//   };

//   const images = [
//     { src: rawmaterials, alt: 'Raw Materials', id: 'rawmaterials' },
//     { src: component, alt: 'Component', id: 'component' },
//     { src: prototype, alt: 'Prototype', id: 'prototype' },
//     { src: manufacturing, alt: 'Manufacturing', id: 'manufacturing' },
//     { src: packaging, alt: 'Packaging', id: 'packaging' },
//     { src: shipment, alt: 'Shipment', id: 'shipment' },
//   ];

//   return (
//     <div className='Procurement-container'>
//      <h1 className='text-black text-4xl text-center'>Our Portfolio</h1><br/><br/><br/>
//      <div className='w-full flex items-center justify-between'>
//      <div className='Procurement-content text-white w-1/3 text-justify'>
//         <p>1.Find Raw materials from Certified Vedors
// Hundreds of different materials available
// Buy tested and certified raw materials</p><br/>
// <p>2.Dedicated product designers 
// Industry specific designs and designers
// Truly inspiring and futuristic designs</p><br/>
// <p>3.Faster and choices of prototyping methods
// Highly precise and tailored models 
// Next gen materials and methods</p><br/>
// <p>4.Find most suitable and affordable manufacturing methods
// Well trained and readymade manpower
// Certified and accessible work places </p><br/>
// <p>5.Well designed and perfect packing 
// Dedicated package testing availability 
// Eco friendly and reusable packing materials </p><br/>
// <p>6.Managed logistics worldwide Integrated SCM 
// Comprehensive support for imports and exports
// </p>
//      </div>
//       <div className="circle-container">
//         <div className="center-image">
//           {hoveredImage ? (
//             <div className="content font-medium">{content[hoveredImage]}</div>
//           ) : (
//             <h3 className='Product-cycle-heading text-white'>Product Cycle</h3>
//           )}
//         </div>
//         <div className="surrounding-images">
//           {images.map(image => (
//             <img
//               key={image.id}
//               src={image.src}
//               alt={image.alt}
//               style={{color:'white'}}
//               className={`surrounding-image ${hoveredImage ? 'move-backwards' : ''} white-image`}
//               onMouseEnter={() => setHoveredImage(image.id)}
//               onMouseLeave={() => setHoveredImage(null)}
//             />
//           ))}
//         </div>
//       </div>
//       </div>
//     </div>
//   );
// };

// export default Procurement;




import React, { useState, useEffect } from 'react';
import component from '../assets/component.png';
import prototype from '../assets/prototype.png';
import manufacturing from '../assets/manufacturing.png';
import packaging from '../assets/package.png';
import shipment from '../assets/tracking.png';
import rawmaterials from '../assets/raw-materials.png';
import './Procurement.css';

const Procurement = () => {
  const paragraphs = [
    "Find Raw materials from Certified Vendors.\nHundreds of different materials available.\nBuy tested and certified raw materials.",
    "Dedicated product designers.\nIndustry-specific designs and designers.\nTruly inspiring and futuristic designs.",
    "Faster and choices of prototyping methods.\nHighly precise and tailored models.\nNext-gen materials and methods.",
    "Find most suitable and affordable manufacturing methods.\nWell-trained and ready-made manpower.\nCertified and accessible workplaces.",
    "Well designed and perfect packing.\nDedicated package testing availability.\nEco-friendly and reusable packing materials.",
    "Managed logistics worldwide.\nIntegrated SCM\nComprehensive support for imports and exports.",
  ];

  const images = [
    { src: rawmaterials, alt: 'Raw Materials', id: 'rawmaterials', textIndex: 0 },
    { src: component, alt: 'Component', id: 'component', textIndex: 1 },
    { src: prototype, alt: 'Prototype', id: 'prototype', textIndex: 2 },
    { src: manufacturing, alt: 'Manufacturing', id: 'manufacturing', textIndex: 3 },
    { src: packaging, alt: 'Packaging', id: 'packaging', textIndex: 4 },
    { src: shipment, alt: 'Shipment', id: 'shipment', textIndex: 5 },
  ];

  const [hoveredImage, setHoveredImage] = useState(null);
  const [currentText, setCurrentText] = useState(paragraphs[0]); // Initialize with the first paragraph
  const [currentIndex, setCurrentIndex] = useState(0); // Track the index of the text
  const [isPaused, setIsPaused] = useState(false); // Track if the interval should be paused

  useEffect(() => {
    if (!hoveredImage) {
      const intervalId = setInterval(() => {
        if (!isPaused) {
          setCurrentIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % paragraphs.length;
            setCurrentText(paragraphs[nextIndex]);
            return nextIndex;
          });
        }
      }, 3000); // Change text every 3 seconds

      return () => clearInterval(intervalId); // Clean up interval on component unmount
    }
  }, [isPaused, hoveredImage]);

  const handleMouseEnter = (textIndex) => {
    setIsPaused(true); // Pause the automatic text rotation
    setHoveredImage(textIndex);
    setCurrentText(paragraphs[textIndex]); // Set the paragraph corresponding to the hovered image
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
    setIsPaused(false); // Resume the automatic text rotation
  };


  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
        <br/>
      </React.Fragment>
    ));
  };


  return (
    <div className='Procurement-container w-full'>
      <h1 className='text-white text-4xl text-center heading-whiteunderline'>Our Portfolio</h1><br /><br /><br />
      <div className='w-full flex items-center justify-between'>
        <div className='Procurement-content text-white max-w-[50%]'>
          
         <p className='text-medium text-1xl w-full whitespace-pre-wrap'>{formatText(currentText)}</p> 
        </div>
        <div className="circle-container">
          <div className="center-image">
            <h3 className='Product-cycle-heading'>Product Cycle</h3>
          </div>
          <div className="surrounding-images">
            {images.map((image) => (
              <img
                key={image.id}
                src={image.src}
                alt={image.alt}
                className={`surrounding-image ${hoveredImage === image.textIndex ? 'move-backwards' : ''} white-image`}
                onMouseEnter={() => handleMouseEnter(image.textIndex)}
                onMouseLeave={handleMouseLeave}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Procurement;
