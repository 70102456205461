// import React, { useRef } from 'react';
// import { motion, useScroll } from 'framer-motion';
// import './Certifications.css';

// const LiIcon = ({ reference }) => {
//   const { scrollYProgress } = useScroll({
//     target: reference,
//     offset: ["center end", "center center"]
//   });

//   return (
//     <figure className='li-icon'>
//       <svg className='rotate-svg' width="75" height="75" viewBox='0 0 100 100'>
//         <circle cx="75" cy="50" r="20" className='circle-base' />
//         <motion.circle 
//           cx="75" 
//           cy="50" 
//           r="20" 
//           className='circle-motion' 
//           style={{ pathLength: scrollYProgress }} 
//         />
//         <circle cx="75" cy="50" r="10" className='circle-pulse' />
//       </svg>
//     </figure>
//   );
// };

// const CertificationItem = ({ certification, reference }) => {
//   return (
//     <li ref={reference} className='certification-item'>
//       <LiIcon reference={reference} />
//       <motion.div 
//         initial={{ y: 50 }} 
//         whileInView={{ y: 0 }} 
//         transition={{ duration: 0.5, type: "spring" }} 
//         className="certification-text"
//       >
//         <h3 className='certification-title'>{certification}</h3>
//       </motion.div>
//     </li>
//   );
// };

// const Certifications = () => {
//   const ref = useRef(null);
//   const certifications = ['ISO 9001', 'ISO 14001', 'ISO 25001', 'NSC', 'DBIIT', 'MSME'];
//   const { scrollYProgress } = useScroll({
//     target: ref,
//     offset: ["start end", "center start"]
//   });

//   return (
//     <div className='certifications-section'>
//       <h2 className='certifications-heading'>
//         Certifications
//       </h2>
//       <div ref={ref} className='certifications-container'>
//         <motion.div 
//           style={{ scaleY: scrollYProgress }} 
//           className='progress-bar'
//         />
//         <ul className='certifications-list'>
//           {certifications.map((certification, index) => (
//             <CertificationItem 
//               key={index} 
//               certification={certification} 
//               reference={ref} 
//             />
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Certifications;






import React, { useRef } from 'react';
import { motion, useScroll } from 'framer-motion';
import LiIcon from './LiIcon';
import './Certifications.css';

const CertificationDetails = ({ title }) => {
    const ref = useRef(null);

    return (
        <li ref={ref} className='certification-item'>
            <LiIcon reference={ref} />
            <motion.div 
                initial={{ y: 50 }} 
                whileInView={{ y: 0 }} 
                transition={{ duration: 0.5, type: "spring" }} 
                className="certification-content"
            >
                <h3 className='certification-title'>{title}</h3>
            </motion.div>
        </li>
    );
};

const Certifications = () => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "center start"],
    });

    const certifications = ['ISO 9001', 'ISO 14001', 'ISO 25001', 'NSC', 'DBIIT', 'MSME'];

    return (
        <div className='certifications-section'>
            <div ref={ref} className='certifications-list-container'>
                <motion.div 
                    style={{ scaleY: scrollYProgress }} 
                    className='certifications-timeline' 
                />
                <ul className='certifications-list'>
                    {certifications.map((cert, index) => (
                        <CertificationDetails key={index} title={cert} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Certifications;
