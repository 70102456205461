import React from 'react';
import Award1 from '../../assets/Award1.png';
import Award2 from '../../assets/Award2.png';
import Award3 from '../../assets/Award3.png';
import Award4 from '../../assets/Award4.png';
import Award5 from '../../assets/Award5.png';
import './Awards.css';

const Awards = () => {
  const awards = [
    { id: 1, src: Award1, alt: 'Award 1' },
    { id: 2, src: Award2, alt: 'Award 2' },
    { id: 3, src: Award3, alt: 'Award 3' },
    { id: 4, src: Award4, alt: 'Award 4' },
    { id: 5, src: Award5, alt: 'Award 5' },
  ];

  return (
    <div className="awards-container p-20">
      {awards.map((award) => (
        <img key={award.id} src={award.src} alt={award.alt} className="award-image" />
      ))}
    </div>
  );
};

export default Awards;
