import React from 'react';


const NewsContent = ({ title, sectionHeader, mainImage, mainHeading, mainDate, mainDescription, articles }) => {
  return (
    <div className="container w-full pr-10">
      <div className="flex items-center mb-4">
        <h2 className="text-lg text-black font-semibold whitespace-nowrap">
          {title}
        </h2>
        <div className="flex-1 h-[4px] bg-gray-300 ml-4"></div>
      </div>
      
      <div className="w-full flex flex-wrap">
        {/* Left Section */}
        <div className="w-[50%] lg:w-1/2 pr-5">
          <img src={mainImage} alt="Main" className="w-96 h-64 object-cover rounded-lg mb-4" />
          <div>
            <div className="text-sm text-gray-600 mb-2">{sectionHeader}</div>
            <h3 className="text-2xl font-semibold text-gray-800 leading-snug">
              {mainHeading}
            </h3>
            <div className="text-sm text-gray-500 mt-2">{mainDate}</div>
            <p className="text-gray-700 mt-4">
              {mainDescription}
            </p>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-1/2">
        <div className="space-y-11">
  {articles.map((article, index) => (
    <div key={index} className="flex space-x-4 w-full">
      <img 
        src={article.image} 
        alt={article.altText} 
        className="w-24 h-20 object-cover rounded-md flex-shrink-0" 
      />
      <div>
        <h4 className="text-sm font-semibold text-gray-800">{article.heading}</h4>
        <p className="text-xs text-gray-500">{article.date}</p>
      </div>
    </div>
  ))}
</div>
        </div>
      </div>
    </div>
  );
};

export default NewsContent;
