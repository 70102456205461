// import React, { useEffect } from 'react'
// import './Insights.css'
// import Navbar from '../components/Navbar'
// import CovetenLogo from '../assets/CovetenLogo.png'
// import { motion , useAnimation} from 'framer-motion';  
// import { useInView } from 'react-intersection-observer';

// import InsightsSpinner from '../components/Spinner/InsightsSpinner';
// import ElectricalElectronics from '../components/ElectricalElectronics/ElectricalElectronics';

// function Insights() {

//   const centerToPositionVariants = {
//     hidden: { opacity: 0, scale: 0.5, x: '50%', y: '50%', translateX: '-50%', translateY: '-50%' },
//     visible: { opacity: 1, scale: 1, x: '0%', y: '0%', translateX: '0%', translateY: '0%' },
//   };

//   const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//       opacity: 1,
//       transition: {
//         staggerChildren: 0.3, // Delay between each card
//       },
//     },
//   };
  

//   const cardVariants = {
//     hidden: { opacity: 0, x: -50 }, // Start off-screen to the left
//     visible: { opacity: 1, x: 0, transition: { duration: 0.6 } }, // Move to original position
//   };


//   const solutions = [
//     'RPA',
//     'Asset Management',
//     'SCM',
//     'Automation',
//     'FA',
//     'ERP',
//   ];


//   const certifications = [
//     'ISO 9001',
//     'ISO 14001',
//     'ISO 25001',
//     'NSC',
//     'DBIIT',
//     'MSME',
//   ];


//   const supportingStandards = [
//     'MIL',
//     'DO 160',
//     'ASTM',
//     'ASTM',
//     'ASME',
//     'NABL',
//     'ISO 9001 ISO 14001 ISO 25001',
//     'FDA',
//     'ISO',
//     'ISI',
//     'CE',
//     'NADCAP'
//   ];


//   const awards = [
//     'Global Best Supplies',
//     'Star of the Year',
//     'FORBES Person of the Year',
//     'Value for time AWARD 2024',
//     'Best Exporter',
//   ];



//   const { ref: part4Ref, inView: part4InView } = useInView({
//     triggerOnce: true, // Trigger the animation only once
//     threshold: 0.2, // 20% of the element should be in view to trigger
//   });


//   const { ref: part5Ref, inView: part5InView } = useInView({
//     triggerOnce: true, // Trigger the animation only once
//     threshold: 0.2, // 20% of the element should be in view to trigger
//   });

//   const { ref: part6Ref, inView: part6InView } = useInView({
//     triggerOnce: true, // Trigger the animation only once
//     threshold: 0.2, // 20% of the element should be in view to trigger
//   });


//   const { ref: part7Ref, inView: part7InView } = useInView({
//     triggerOnce: true, // Trigger the animation only once
//     threshold: 0.2, // 20% of the element should be in view to trigger
//   });

//   const controls = useAnimation();
//   const controlsPart6 = useAnimation();
//   const controlsPart7 = useAnimation();
  

//   useEffect(() => {
//     if (part4InView) {
//       controls.start('visible');
//     }
//   }, [controls, part4InView]);


//   useEffect(() => {
//     if (part5InView) {
//       controls.start('visible');
//     }
//   }, [controls, part5InView]);


//   useEffect(() => {
//     if (part6InView) {
//       controls.start('visible');
//     }
//   }, [controlsPart6, part6InView]);


//   useEffect(() => {
//     if (part7InView) {
//       controls.start('visible');
//     }
//   }, [controlsPart7, part7InView]);


//   return (
//     <div className='Insightsmain-Container'>
//         <Navbar/>
//         <div className='Insights-part1'>
//            <motion.div
//           className='Insights-row1'
//           initial='hidden'
//           animate='visible'
//           variants={centerToPositionVariants}
//           transition={{ duration: 0.6, ease: 'easeOut' }}
//         >
//           <div className='Left-logo'>
//             <img src={CovetenLogo} alt="Logo" className='coveten-logo' />
//           </div>
//           <div className='Right-content'>
//             <h3>The Coveten’s Club</h3>
//             <p>
//               We have carefully qualified and added hundreds of manufacturers 
//               across the country into the Coveten’s club. These club members 
//               are properly trained on all aspects such as qualification of material, 
//               processing, handling, safety, and quality aspects. Every order, project, 
//               or testing, our members are well-versed to complete precisely and on time.
//             </p>
//             <button className="back-login">Join us (Take to Login page)</button>
//           </div>
//         </motion.div>

//           <motion.div
//           className='Insights-row2'
//           initial='hidden'
//           animate='visible'
//           variants={centerToPositionVariants}
//           transition={{ duration: 0.6, ease: 'easeOut', delay: 0.3 }}
//         >
//           <div className='row2-content'>
//             <h3>The Coveten’s Club</h3>
//             <p>
//               We have carefully qualified and added hundreds of manufacturers 
//               across the country into the Coveten’s club. These club members 
//               are properly trained on all aspects such as qualification of material, 
//               processing, handling, safety, and quality aspects. Every order, project, 
//               or testing, our members are well-versed to complete precisely and on time.
//             </p>
//           </div>
//         </motion.div>

//         </div>     
//         <motion.div
//           className='Insights-part2'
//           initial='hidden'
//           animate='visible'
//           variants={centerToPositionVariants}
//           transition={{ duration: 0.6, ease: 'easeOut', delay: 0.3 }}
//         >
//             <h3 className='underline'>Our Mechanical Solutions</h3>         
//               <InsightsSpinner/>
//         </motion.div>


//         <div className='Insights-part3'>
// <ElectricalElectronics/>
// </div>

// <div className='Insights-part4'>
//   <h3 className='underline'>Our IOT 4.0 - AI & ML Solutions</h3>
// <motion.div
//           className='card-grid'
//           initial='hidden'
//           animate={controls}
//           variants={containerVariants}
//           ref={part4Ref}
//         >
//           {solutions.map((solution, index) => (
//             <motion.div
//               key={index}
//               className='solution-card'
//               variants={cardVariants}
//             >
//               {solution}
//             </motion.div>
//           ))}
//         </motion.div>
// </div>


// <motion.div
//         className='Insights-part5'
//         ref={part5Ref}
//         initial='hidden'
//         animate={controls}
//         variants={containerVariants}
//       >
//         <h3 className='underline'>Our Certifications</h3>
//         <div className='certification-cards-container'>
//           {certifications.map((certification, index) => (
//             <motion.div
//               key={index}
//               className='certification-card'
//               variants={cardVariants}
//               style={{ display: 'inline-block' }}
//             >
//               {certification}
//             </motion.div>
//           ))}
//         </div>
//       </motion.div>





// <motion.div
//         className='Insights-part6'
//         ref={part6Ref}
//         initial='hidden'
//         animate={controlsPart6}
//         variants={containerVariants}
//       >
//         <h3 className='underline'>Supporting Standards</h3>
//         <div className='supporting-cards-container'>
//           {supportingStandards.map((standard, index) => (
//             <motion.div
//               key={index}
//               className='supporting-card'
//               variants={cardVariants}
//               style={{ display: 'inline-block' }}
//             >
//               {standard}
//             </motion.div>
//           ))}
//         </div>
//       </motion.div>


// {/* <div className='Insights-part7'>
//   <h3 className='underline'>Awards & Recognitions</h3>
//   <div className='awards-container'>
//     <div className='award-card'>Card 1</div>
//     <div className='award-card'>Card 2</div>
//     <div className='award-card'>Card 3</div>
//     <div className='award-card'>Card 4</div>
//     <div className='award-card'>Card 5</div>
//   </div>
// </div> */}




// <motion.div
//         className='Insights-part7'
//         ref={part7Ref}
//         initial='hidden'
//         animate={controlsPart7}
//         variants={containerVariants}
//       >
//         <h3 className='underline'>Awards & Recognitions</h3>
//         <div className='awards-container'>
//           {awards.map((award, index) => (
//             <motion.div
//               key={index}
//               className='award-card'
//               variants={cardVariants}
//               style={{ display: 'inline-block' }}
//             >
//               {award}
//             </motion.div>
//           ))}
//         </div>
//       </motion.div>


// <div className='Insights-part8'>
//   <h3 className='underline'>News</h3>
//   <div className='news-container'>
//     <div className='news-card'>
//       <h4 className='news-title'>News Title 1</h4>
//       <p className='news-content'>Content for the first news item. This is a brief description or summary of the news article.</p>
//     </div>
//     <div className='news-card'>
//       <h4 className='news-title'>News Title 2</h4>
//       <p className='news-content'>Content for the second news item. This is a brief description or summary of the news article.</p>
//     </div>
//     <div className='news-card'>
//       <h4 className='news-title'>News Title 3</h4>
//       <p className='news-content'>Content for the third news item. This is a brief description or summary of the news article.</p>
//     </div>
//   </div>
// </div>

//     </div>
//   )
// }

// export default Insights









import React, { useEffect, useRef, useState } from 'react';
import {delay, motion} from 'framer-motion'
import './Insights.css'
import AnimatedSection from '../components/InsightsCardsAnimation/AnimatedSection';
import ElectricalElectronics from '../components/ElectricalElectronics/ElectricalElectronics';
import { useInView } from 'react-intersection-observer';
import AnimatedTitle from '../components/TextAnimations/AnimatedTitle';
// import VectorImg from '../../src/assets/image.png'
import WorldImg from '../../src/assets/World.png'
import AiMlSolutions from '../components/AiMlSolutions/AiMlSolutions';
import Certifications from '../components/Certifications/Certifications';
import Awards from '../components/Awards/Awards';
import CircleComponent from '../components/Animation/CircleComponent';
import NewsComponent from '../components/Animation/NewsComponent';

const Insights = () => {
  const [showContent, setShowContent] = useState(false);
  const centerToPositionVariants = {
    hidden: { opacity: 0, scale: 0.5, x: '50%', y: '50%', translateX: '-50%', translateY: '-50%' },
    visible: { opacity: 1, scale: 1, x: '0%', y: '0%', translateX: '0%', translateY: '0%' },
  };

  const solutions = [
        'RPA',
        'Asset Management',
        'SCM',
        'Automation',
        'FA',
        'ERP',
      ];


     const Mechanicalsolutions=[
      'Vacuum Forming',
       '3D Printing',
       'Laser Cutting',
       'CNC Machining',
       'Injection Moulding',
       'DIE Casting',
       'Forging',
       'Sheet Metal Stamping',
       'Investment Casting',
     ]
     

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
  };



  const slideInVariants = {
    hidden: { opacity: 0, x: '-100%' },
    visible: { opacity: 1, x: '0%', transition: { duration: 0.8 } },
  };

  const zoomInVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
  };

  const fadeInAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1,delay: 1 } },
  };

  const insightsPart9Ref = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const inView = useInView(insightsPart9Ref, { once: true });

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);


  const handleAnimationComplete = () => {
    setShowContent(true);
  };

  const newsItems = [
    { title: 'News Title 1', content: 'Content for the first news item. This is a brief description or summary of the news article.' },
    { title: 'News Title 2', content: 'Content for the second news item. This is a brief description or summary of the news article.' },
    { title: 'News Title 3', content: 'Content for the third news item. This is a brief description or summary of the news article.' }
  ];

  return (
    <div className='Insightsmain-Container'>
      <div className="section bg-white h-full p-20 py-40 pb-10 w-full">
     
      <div className='flex items-center w-full'>
      <motion.div
          className=''
          initial='hidden'
          animate='visible'
          variants={centerToPositionVariants}
          transition={{ duration: 0.6, ease: 'easeOut' }}
        >
        <div className="img-container w-[35em] mr-10">
          <img src={WorldImg} alt="World" className='block w-full h-full object-cover'/>
        </div>
        </motion.div>
        <div className="block">
        <AnimatedTitle text="The Coveten’s Club" onAnimationComplete={handleAnimationComplete} />
                
        <motion.p
          className='text-black mt-5 text-justify font-medium'
          initial="hidden"
          animate="visible"
          variants={fadeInAnimation}
          
        >
          We have carefully qualified and added hundreds of manufacturers 
          across the country into the Coveten’s club. These club members 
          are properly trained on all aspects such as qualification of material, 
          processing, handling, safety, and quality aspects. Every order, project, 
          or testing, our members are well-versed to complete precisely and on time.
        </motion.p>       
        </div>
      </div>

      


      </div>

      <div className='block  bg-white pb-36 px-36'>
      <AnimatedTitle text="Our Capabilities" onAnimationComplete={handleAnimationComplete} />
      <motion.p
          className='text-black mt-5 text-justify font-medium'
          initial="hidden"
          animate="visible"
          variants={fadeInAnimation}
        >
            With more than 1.25 lakh sqft of manufacturing space, 1250 CNC machines,
             1400 Turning machines, 15000+ work force, more than 180 designers,
             more than 150 3D printing machines with over 14 difference technologies
              The Coveten’s club is leading manufacturing, supply chain and Industrial Quality 
              network in the country ...  our numbers are growing
              </motion.p>
      </div>



{/* <div className='w-full bg-[royalblue] p-20'> */}
{/* <div className='w-full bg-[lightseagreen] p-20'> */}
<div className='w-full bg-[white] p-20'>
  <h1 className='relative text-4xl text-[lightseagreen] text-center mb-20 heading-with-blueunderline'>
    Our Stock Products
  </h1>  
  {/* <ElectricalElectronics/> */}

  <div className="w-full flex items-center justify-between p-20">
    <h1 className="text-2xl text-[black]  neon-glow">Connectors</h1>
    <h1 className="text-2xl text-[black] neon-glow">Harness</h1>
    <h1 className="text-2xl text-[black] neon-glow">Electronic Components</h1>
    <h1 className="text-2xl text-[black] neon-glow">Sensors</h1>
</div>

</div>




{/* <div className='w-full bg-[#414141] p-20'> */}
<div className='w-full bg-[white] p-20'>
<h1 className='relative text-4xl text-[lightseagreen] text-center mb-20 heading-with-blueunderline'>Our IOT 4.0 - AI & ML Solutions</h1>
{/* <h1 className='relative text-4xl text-white text-center mb-20 heading-with-underline'>Our IOT 4.0 - AI & ML Solutions</h1> */}
{/* <AiMlSolutions/> */}
<div className="w-full flex items-center justify-between p-20">
{solutions.map((solution, index) => (
        <h1 key={index} className="text-2xl text-[black] neon-glow">
            {solution}
        </h1>
    ))}
</div>
</div>



<div className='w-full bg-[white] p-20'>
<h1 className='relative text-4xl text-[lightseagreen] text-center mb-20 heading-with-blueunderline'>Our Manufacturing Solutions</h1>
{/* <h1 className='relative text-4xl text-white text-center mb-20 heading-with-underline'>Our IOT 4.0 - AI & ML Solutions</h1> */}
{/* <AiMlSolutions/> */}
<div className="w-full flex items-center justify-between gap-10 flex-wrap p-20">
{Mechanicalsolutions.map((solution, index) => (
        <h1 key={index} className="text-2xl text-[black] neon-glow whitespace-nowrap">
            {solution}
        </h1>
    ))}
</div>
</div>


<div className='w-full bg-white p-20'>
{/* <h3 className='relative text-4xl text-blue-600 text-center mb-20 heading-with-blueunderline'>Our Certifications</h3> */}
<h3 className='relative text-4xl text-[lightseagreen] text-center mb-20 heading-with-blueunderline'>Our Certifications</h3>
<Certifications/>
</div>



<div className='w-full p-20 bg-[lightseagreen] flex flex-col items-center justify-between'>
{/* <h3 className='relative text-4xl text-blue-600 text-center mb-20 heading-with-blueunderline'>Supporting Standards</h3> */}
<h3 className='relative  text-4xl text-[white] text-center mb-10 heading-with-blackunderline'>Supporting Standards</h3>
<CircleComponent/>
</div>


<div className='w-full p-20 bg-[#0f0326] flex flex-col items-center justify-between'>
<h3 className='relative text-4xl text-white text-center mb-10  heading-with-underline'>Awards & Recognitions</h3>   
          <Awards/>
</div>


<div className='w-full p-20 bg-[#e2e1e0]'>
{/* <h3 className='relative text-4xl text-blue-600 text-center mb-20 heading-with-blueunderline'>News</h3> */}
<h3 className='relative text-4xl text-[lightseagreen] text-center mb-20 heading-with-blueunderline'>News</h3>
<NewsComponent/>
</div>

      {/* <div className='Insights-part1'>
            <motion.div
          className='Insights-row1'
          initial='hidden'
          animate='visible'
          variants={centerToPositionVariants}
          transition={{ duration: 0.6, ease: 'easeOut' }}
        >
           <div className='Right-content'>
            <div className='vectorimage-container'> 
              <img src={VectorImg} alt='vectorimg' width={500} height={500}/>
            </div>
        <div className='Insights-text-container'>
      <AnimatedTitle text="The Coveten’s Club" onAnimationComplete={handleAnimationComplete} />
      <br/>
      {showContent && (        
        <motion.p
          className='coveten-club-content'
          initial="hidden"
          animate="visible"
          variants={fadeInAnimation}
        >
          We have carefully qualified and added hundreds of manufacturers 
          across the country into the Coveten’s club. These club members 
          are properly trained on all aspects such as qualification of material, 
          processing, handling, safety, and quality aspects. Every order, project, 
          or testing, our members are well-versed to complete precisely and on time.
        </motion.p>
      )}
      </div>
    </div>
        </motion.div>

          <motion.div
          className='Insights-row2'
          initial='hidden'
          animate='visible'
          variants={centerToPositionVariants}
          transition={{ duration: 0.6, ease: 'easeOut', delay: 0.3 }}
        >
          <div className='row2-content'>
          <AnimatedTitle text="Our Capabilities" onAnimationComplete={handleAnimationComplete} />
          <br/>
          {showContent && (        
        <motion.p
          className='capabilities-content'
          initial="hidden"
          animate="visible"
          variants={fadeInAnimation}
        >
            With more than 1.25 lakh sqft of manufacturing space, 1250 CNC machines,
             1400 Turning machines, 15000+ work force, more than 180 designers,
             more than 150 3D printing machines with over 14 difference technologies
              The Coveten’s club is leading manufacturing, supply chain and Industrial Quality 
              network in the country ...  our numbers are growing
              </motion.p>
          )}
          </div>
        </motion.div>
        </div>


      <div  className='Insights-part3'>
      <h1 className='Insights-part3-heading'>Our Stock Products</h1>   
          <ElectricalElectronics/>
      </div>

        <div className='Insights-part4'>
        <h3 className='Insights-part4-heading'>Our IOT 4.0 - AI & ML Solutions</h3>
        <AiMlSolutions/>
        </div>

  
        <div className='Insights-part5'>
         <h3 className='Insights-part5-heading'>Our Certifications</h3>
        <Certifications/>
      </div>

      <AnimatedSection
        variants={containerVariants}
        transition={{ duration: 0.6, ease: 'easeOut' }}
        className='Insights-part6'
      >
        <h3 className='Insights-part6-heading'>Supporting Standards</h3>
         <CircleComponent/>
      </AnimatedSection>

     <div className='Insights-part7'>
        <h2 className='Insights-part7-heading'>Awards & Recognitions</h2>
        <div className='awards-container'>
          <Awards/>
        </div>
        </div>
    
      <AnimatedSection
        variants={containerVariants}
        transition={{ duration: 0.6, ease: 'easeOut' }}
        className='Insights-part8'
      >
        <h3 className='Insights-part8-heading'>News</h3>
        <NewsComponent/>
      </AnimatedSection> */}

    </div>

  // <div className='w-full text-black bg-white'>
  //    <div className="img-container w-[35em]">
  //     <img src={VectorImg} alt="" className='block w-full h-full object-cover'/>
  //    </div>
  // </div>
  );  
};

export default Insights;















