// import React, { useState } from 'react';
// import { FaQuestion, FaChalkboardTeacher } from 'react-icons/fa';
// import './SidebarTab.css';

// const SidebarTab = () => {
//   const [hovered, setHovered] = useState('');

//   return (
//     <div className="tabs">
//       <div
//         className={`tab ${hovered === 'enquire' ? 'active' : ''}`}
//         onMouseEnter={() => setHovered('enquire')}
//         onMouseLeave={() => setHovered('')}
//       >
//         <div className="icon"><FaQuestion /></div>
//         <div className="text">Enquire Now</div>
//       </div>
//       <div
//         className={`tab ${hovered === 'demo' ? 'active' : ''}`}
//         onMouseEnter={() => setHovered('demo')}
//         onMouseLeave={() => setHovered('')}
//       >
//         <div className="icon"><FaChalkboardTeacher /></div>
//         <div className="text">Demo</div>
//       </div>
//     </div>
//   );
// };

// export default SidebarTab;












import React, { useState } from 'react';
import { FaQuestion, FaChalkboardTeacher } from 'react-icons/fa';
import './SidebarTab.css';

const SidebarTab = () => {
  const [hovered, setHovered] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);

  const openForm = () => setIsFormVisible(true);
  const closeForm = () => setIsFormVisible(false);

  return (
    <div>
      <div className="tabs">
        <div
          className={`tab ${hovered === 'enquire' ? 'active' : ''}`}
          onMouseEnter={() => setHovered('enquire')}
          onMouseLeave={() => setHovered('')}
          onClick={openForm} // Open form on click
        >
          <div className="icon"><FaQuestion /></div>
          <div className="Sidebar-text">Enquire Now</div>
        </div>
        <div
          className={`tab ${hovered === 'demo' ? 'active' : ''}`}
          onMouseEnter={() => setHovered('demo')}
          onMouseLeave={() => setHovered('')}
        >
          <div className="icon"><FaChalkboardTeacher /></div>
          <div className="Sidebar-text">Demo</div>
        </div>
      </div>

      {/* Background overlay and form */}
      {isFormVisible && (
        <div className="overlay">
          <div className="form-container">
            <h2>Enquire Now</h2>
            <button className="close-button" onClick={closeForm}>Close</button>
            
            <form>
              <label>Name:
                <input type="text" name="name" required />
              </label>
              <label>Phone Number:
                <input type="tel" name="phone" required />
              </label>
              <label>Email:
                <input type="email" name="email" required />
              </label>
              <label>How can we help you?:
                <textarea name="message" rows="4" required></textarea>
              </label>
              <label>CAPTCHA:
                <input type="text" name="captcha" required placeholder="Enter CAPTCHA" />
              </label>
              <label>Attach File:
                <input type="file" name="attachment" />
                <small>We support most document types</small>
              </label>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarTab;
