// import React from 'react';
// import './CircleComponent.css';

// const supportingStandards = [
//     'MIL', 'DO 160', 'ASTM', 'ASME', 'NABL',
//     'FDA', 'ISO', 'ISI', 'CE', 'NADCAP'
//   ];

// const CircleComponent = () => {
//   return (
//     <div className="circles-container">
//       <div className="circles-row">
//         {supportingStandards.slice(0, 6).map((standard, index) => (
//           <div className="circles-wrapper" key={index}>
//             <input type="checkbox" className='circle-input'/>
//             <label className='circle-label'>
//               <i className='icon-off'>{standard}</i>
//             </label>
//           </div>
//         ))}
//       </div>
//       <div className="circles-row">
//         {supportingStandards.slice(6).map((standard, index) => (
//           <div className="circles-wrapper" key={index}>
//             <input type="checkbox" className='circle-input'/>
//             <label className='circle-label'>
//               <i className='icon-off'>{standard}</i>
//             </label>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CircleComponent;



import React from 'react';
import './CircleComponent.css';

const supportingStandards = [
    'MIL', 'DO 160', 'ASTM', 'ASME', 'NABL',
    'FDA', 'ISO', 'ISI', 'CE', 'NADCAP'
];

const CircleComponent = () => {
  return (
    <section className="hexagon-gallery p-20">
      {supportingStandards.map((standard, index) => (
        <div className="hex" key={index}>
          <span className="hex-text">{standard}</span>
        </div>
      ))}
    </section>
  );
};

export default CircleComponent;
