// import React from 'react'
// import Footer from './Footer/Footer'
// import Navbar from './Navbar'




// function Layout({children}) {
  
//   return (
//     <div className='full-width'>
//       <Navbar/>
//       <main>
//         {children}
//       </main>
//     <Footer/>
//     </div>
//   )
// }

// export default Layout









import React, { useEffect } from 'react';
import Footer from './Footer/Footer';
import Navbar from './Navbar';
import Loader from './Loader';
import { useLoading } from '../context/LoadingContext';
import { useLocation } from 'react-router-dom';

function Layout({ children }) {
  const { loading, setLoading } = useLoading();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust time as needed

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [location, setLoading]);

  return (
    <div className='full-width'>
      {loading ? (
        <div className='fixed inset-0 bg-white flex items-center justify-center z-50'>
          <Loader />
        </div>
      ) : (
        <>
          <Navbar />
          <main>
            {children}
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Layout;
