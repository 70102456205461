import './App.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
import Layout from './components/Layout';
import Insights from './pages/Insights';
import Benefits from './pages/Benefits';
// import CookieConsent from "react-cookie-consent";
import ProductsCategories from './components/Productcategory/ProductsCategories';
import ProductsDescription from './components/ProductDescription/ProductsDescription';
import CookiesPolicy from './components/terms/CookiesPolicy';
import { LoadingProvider } from './context/LoadingContext';






function App() {
  return (
    <div className="App">
    <Router>
      <LoadingProvider>
      <Layout>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products/>} />
        <Route path="/products/product-categories/:category" element={<ProductsCategories/>} />
        <Route path="/products/product-description/:productId" element={<ProductsDescription/>} />
        <Route path='/insights' element={<Insights/>}/>
        <Route  path='/benefits' element={<Benefits/>}/>
        <Route path="/terms/cookies_policy" element={<CookiesPolicy />} />
      </Routes>
      {/* <CookieConsent
  location="bottom"
  buttonText="Agree"
  declineButtonText="Decline"
  cookieName="myAppCookieConsent"
  style={{ background: "#242424", color: "#FFF" }}
  buttonStyle={{ color: "#FFF", background: "lightseagreen", fontSize: "14px" }}
  declineButtonStyle={{ color: "#FFF", background: "lightseagreen", fontSize: "14px" }}
  expires={150}
  enableDeclineButton={true}
>
 <span className='text-xs'>We use cookies on our website to provide you with a more personalized digital experience. To learn more about how we use cookies and how you can change your cookie settings, please refer to the following: </span> <Link to="#" style={{ color: "lightseagreen" ,fontSize:"12px"}}>Privacy Statement</Link>
</CookieConsent> */}

      </Layout>
      </LoadingProvider>
    </Router>
   
    </div>
  );
}

export default App;
