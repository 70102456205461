import React, { useState } from 'react'
import './DropDownMenu.css'

function DropDownMenu({ label, options }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className={`dropdown ${isOpen ? 'open' : ''}`}>
        <button className="dropdown-button" onClick={handleToggle}>
          {label}
        </button>
        <div className="dropdown-content">
          {options.map((option, index) => (
            <a key={index} href="#">
              {option}
            </a>
          ))}
        </div>
      </div>
    );
  }
  


export default DropDownMenu