// import React from 'react';
// import { motion, useScroll } from 'framer-motion';
// import './LiIcon.css';

// const LiIcon = ({ reference }) => {
//     const { scrollYProgress } = useScroll({
//         target: reference,
//         offset: ["center end", "center center"],
//     });

//     return (
//         <figure className='li-icon'>
//             <svg className='li-icon-svg' width="75" height="75" viewBox='0 0 100 100'>
//                 <circle cx="75" cy="50" r="20" className='li-icon-circle' />
//                 <motion.circle
//                     cx="75" 
//                     cy="50" 
//                     r="20" 
//                     className='li-icon-motion-circle'
//                     style={{
//                         pathLength: scrollYProgress,
//                     }}
//                 />
//                 <circle cx="75" cy="50" r="10" className='li-icon-pulse-circle' />
//             </svg>
//         </figure>
//     );
// };

// export default LiIcon;






// import React from 'react';
// import { motion, useScroll } from 'framer-motion';
// import './LiIcon.css';

// const LiIcon = ({ reference }) => {
//     const { scrollYProgress } = useScroll({
//         target: reference,
//         offset: ["center end", "center center"],
//     });

//     return (
//         <figure className='li-icon'>
//             <svg className='li-icon-svg' width="75" height="75" viewBox='0 0 100 100'>
//                 {/* Outer static circle */}
//                 <circle 
//                     cx="75" 
//                     cy="50" 
//                     r="20" 
//                     className='li-icon-circle' 
//                 />
//                 {/* Outer animated circle */}
//                 <motion.circle
//                     cx="75" 
//                     cy="50" 
//                     r="20" 
//                     className='li-icon-motion-circle'
//                     style={{
//                         pathLength: scrollYProgress,
//                     }}
//                 />
//                 {/* Inner pulse circle */}
//                 <circle 
//                     cx="75" 
//                     cy="50" 
//                     r="10" 
//                     className='li-icon-pulse-circle' 
//                 />
//             </svg>
//         </figure>
//     );
// };

// export default LiIcon;

            




import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './LiIcon.css';

const LiIcon = ({ reference }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.5,
    });

    return (
        <figure className='li-icon' ref={(node) => {
            ref(node);
            if (reference && typeof reference === 'function') {
                reference(node);
            }
        }}>
            <svg className='li-icon-svg' width="75" height="75" viewBox='0 0 100 100'>
                {/* Outer static circle */}
                <circle 
                    cx="75" 
                    cy="50" 
                    r="20" 
                    className='li-icon-circle' 
                />
                {/* Outer animated circle */}
                <motion.circle
                    cx="75" 
                    cy="50" 
                    r="20" 
                    className='li-icon-motion-circle'
                    initial={{ pathLength: 0, fill: 'none' }}
                    animate={{ 
                        pathLength: inView ? 1 : 0,
                        fill: inView ? '#f5f5f5' : 'none'
                    }}
                    transition={{ duration: 0.8, ease: "easeInOut" }}
                />
                {/* Inner pulse circle */}
                <circle 
                    cx="75" 
                    cy="50" 
                    r="10" 
                    className='li-icon-pulse-circle' 
                />
            </svg>
        </figure>
    );
};

export default LiIcon;
