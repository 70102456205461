// // src/components/ReusableCarousel.js
// import React from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import './AdsSlider.css'; // You can use this for custom styling

// function AdsSlider({ images }) {
//   return (
//     <Carousel
//       showArrows={true}
//       autoPlay={true}
//       infiniteLoop={true}
//       showStatus={false}
//       showIndicators={false}
//       interval={1000} // Time between slides in milliseconds (3 seconds)
//     //   transitionTime={500}
//     >
//       {images.map((image, index) => (
//         <div key={index} style={{ position: 'relative', height: '20em' }}>
//           <img
//             src={image.src}
//             alt={`Slide ${index + 1}`}
//             style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//           />
//         </div>
//       ))}
//     </Carousel>
//   );
// }

// export default AdsSlider;







// src/components/HorizontalScroll.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AdsSlider.css'; // Custom styling

function AdsSlider({ images }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust speed as needed
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='w-full overflow-hidden '>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className='slide-item w-full overflow-hidden'>
            <img
              src={image.src}
              alt={`Slide ${index + 1}`}
              className='slide-image w-full'
            />
          </div>
        ))}
      </Slider>
     </div>
  );
}

export default AdsSlider;

