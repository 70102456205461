import React, { useEffect, useState } from 'react'
import './ProductsDescription.css'
import { useParams } from 'react-router-dom'
import PrinterImg from '../../assets/Printer.png'
import HammerImg from '../../assets/Hammer.png'
import XRayTubeImg from '../../assets/X-Raytube.png'

import Navbar from '../Navbar'
import ProductDetails from './ProductDetails/ProductDetails'
import SidebarTab from './SidebarTab/SidebarTab'

function ProductsDescription() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);


  const images = [PrinterImg, HammerImg, XRayTubeImg];

  useEffect(() => {
    // Replace this with your actual data fetching logic
    const fetchProductDetails = async () => {
      // Mock data for example purposes
      const id = parseInt(productId, 10);
      const imageIndex = Math.floor((id - 1) / 3) % images.length; // Calculate correct image index
      const productData = {
        id,
        title: `Product ${id}`,
        image: images[imageIndex],
        price: `$${id * 10}`,
        content: `Description of product ${id}`,
      };
      setProduct(productData);
      setSelectedImage(images[imageIndex]); // Set the initial selected image
    };

    fetchProductDetails();
  }, [productId]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className='ProductDescription-main-container'>
      <Navbar/>
      <div className='Productviewer-container'>
      <h1 className='Productviewer-title'>{product.title}</h1>     
      <hr style={{width:'60%', textAlign:'left', marginTop:'1em'}}/>
      <br/><br/>

      <div className='Productviewer-Img-Container'>
      <div className='productviewer-cards-container'>
          {images.map((image, index) => (
            <div
              key={index}
              className='productviewer-card'
              onClick={() => setSelectedImage(image)}
            >
              <img src={image} alt={`Product ${index + 1}`} className='card-image' />
            </div>
          ))}
        </div>
      <div className='Productviewer'>
      <img src={selectedImage} alt={product.title} className='selected-image' />
      </div>
     
        <div className='ProductDescription-container'>
          <h3>Description</h3>
        </div>
        </div>
       
      </div><br/><br/>
      <ProductDetails/><br/><br/>
      <div className='related-product-section'>
            <h3>related Products</h3>
      </div>
      <SidebarTab/>
    </div>
  )
}

export default ProductsDescription