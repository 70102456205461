import React from 'react';
import './NewsComponent.css';

const NewsComponent = () => {
  const newsItems = [
    { title: 'News Title 1', content: 'Content for the first news item. This is a brief description or summary of the news article.' },
    { title: 'News Title 2', content: 'Content for the second news item. This is a brief description or summary of the news article.' },
    { title: 'News Title 3', content: 'Content for the third news item. This is a brief description or summary of the news article.' }
  ];

  return (
    <div className="news-container">
      {newsItems.map((news, index) => (
        <div className="card card-1" key={index}>
          <h2>{news.title}</h2>
          <p>{news.content}</p>
        </div>
      ))}
    </div>
  );
};

export default NewsComponent;
