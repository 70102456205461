import React from 'react'
import './Home.css'
import Slider from '../components/Slider'
import Capabilities from '../components/Capabilities'
import Services from '../components/Services'
import ServiceNetwork from '../components/ServiceNetwork'
import Procurement from '../components/Procurement'

import NetworkAnimation from '../components/Animation/NetworkAnimation'
import ServiceNetworkGlobe from '../components/Animation/ServiceNetwrokGlobe'
import Statistics from '../components/Animation/Statistics'
import ParticlesComponent from '../components/Animation/ParticlesComponent'
import CardSlider from '../components/Animation/CardSlider'
// import ParticlesComponent from '../components/Animation/ParticlesComponent'

// import HomePageVedio from '../assets/localassets/MergedHomePageVideo.mp4'


function Home() {
  // const video = 'https://storage.googleapis.com/coveten-428914.appspot.com/MergedHomePageVideo.mp4';
  const video = 'https://storage.googleapis.com/coveten-428914.appspot.com/Optimizedhomepagevideo.mp4';
  return (
    <div className="Home">

<div className='w-full h-full z-40'>
    <video className="w-full h-full" playsinline autoPlay loop muted>

          <source src={video} type="video/mp4"/>


        </video>
    </div>
      
      {/* <div className='Slider-container'>
          <Slider />
        </div> */}

<div className="capabilities-section">
        <ParticlesComponent id="particles" />
       
          <Capabilities />
       
      </div>
      <div className="service-section">
        <Services/>
      </div>
      <div className='service-network-section'>
        {/* <ServiceNetwork/>     */}
        <ServiceNetworkGlobe/>
      </div>
      <div className='procurement-section'>
        <Procurement/>
        {/* <Statistics/> */}
      </div>
      <div className='companystatus-section'>
        {/* <CompanyStatus/> */}
        <CardSlider/>
      </div>
  </div>
  )
}

export default Home