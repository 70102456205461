// src/components/AccordionServiceSection.js
import React from 'react';
import AccordionItem from './AccordionItem';
import './ServiceSection.css'; // Ensure you have appropriate styles

const ServiceSection = ({ services, openSection, handleToggle }) => {
  return (
    <div className="accordion">
      {Object.entries(services).map(([title, content]) => (
        <AccordionItem
          key={title}
          title={title}
          content={content}
          isOpen={openSection === title}
          onToggle={() => handleToggle(title)}
        />
      ))}
    </div>
  );
};

export default ServiceSection;
