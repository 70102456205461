import React from 'react';
import Banner from './Banner'; 


const ads = [
  { id: 1, banner: true, image: require('../../assets/Img3.jpg') },
  { id: 2, banner: false, image: require('../../assets/images/tpost/1.jpg') },
  { id: 3, banner: false, image: require('../../assets/images/tpost/2.png') },
  { id: 4, banner: false, image: require('../../assets/images/tpost/3.png') },
  { id: 5, banner: false, image: require('../../assets/images/tpost/4.png') },
  { id: 6, banner: false, image: require('../../assets/images/tpost/5.jpg') },
  { id: 7, banner: false, image: require('../../assets/images/tpost/6.png') },
  { id: 8, banner: false, image: require('../../assets/images/tpost/7.png') },
  { id: 9, banner: false, image: require('../../assets/images/popular/395-x-250.png') },
  { id: 10, banner: false, image: require('../../assets/images/popular/Advert-2-CTR-Ella-Henderson-395x250px.jpg') }, // small banner
  { id: 11, banner: false, image: require('../../assets/images/popular/Aggreko-Event-industry-news-banner-395-x-250-px.png') },
  { id: 12, banner: false, image: require('../../assets/images/popular/Award-Wiining-Event-Agency.png') },
  { id: 13, banner: false, image: require('../../assets/images/popular/Captello-PLC-Web-Banner-ETL.png') },
  { id: 14, banner: false, image: require('../../assets/images/popular/Event-Staffing-Software-Made-Simple-2.png') },
];

function AdvertisementSection() {
  return (
    <div className="flex flex-col space-y-4">
      {ads.map((ad) => (
        <div key={ad.id} className="w-full">
          {ad.id === 1 ? (
            <Banner />
          ) : (
            <div
              className={`${
                ad.banner ? 'h-20' : 'h-60'
              } bg-white shadow-md overflow-hidden`}
            >
              <div
                className="w-full h-full bg-center bg-cover"
                style={{ backgroundImage: `url(${ad.image})` }}
                alt={`Ad ${ad.id}`}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}




export default AdvertisementSection;
