import React from 'react';
import './CookiesPolicy.css'

const CookiesPolicy = () => {
  return (
    <div className='CookiesPolicy-maincontainer'>
      <h1>Cookies Policy</h1>
        <div className='CookiesPolicy-container'>
        <p className='paragraph'>COVETEN technologies India LLp is global company, committed to protecting your personal data and respecting your privacy rights. 
        This “Cookies Policy” is intended to provide information on how we, COVETEN technologies India LLp, use cookies and similar technologies
         on COVETEN technologies India LLp websites/webapp,
         For more information on how COVETEN technologies India LLp handles your personal data in general, visit our Privacy Statement.</p>
            <p className='paragraph'>&nbsp;</p>
            <p className='paragraph'><strong>What Are Cookies?</strong></p>
        <p className='paragraph'></p>
        </div>
    </div>
  );
};

export default CookiesPolicy;
