import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

new ResizeObserver(() => {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }).observe(document.documentElement);
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
    <App />
);

