import React, { useEffect, useRef, useState } from 'react'
import ServiceSection from './AccordionServiceSection/ServiceSection';
import './Services.css'
import {motion} from 'framer-motion'
import Heading from './Headings/Heading';

function Services() {
  const services = {
    "Rapid Prototyping Services": [
      "3D Printing",
      "Injection Molding",
      "Clay or Wax Modeling",
      "CNC Machining",
      "Rapid Casting",
      "Laser Cutting",
      "Vacuum Forming",
      "Sheet Metal Prototyping",
      "Composite Layup",
      "Water Jet Cutting",
    ],
    "Design Engineering Services": [
      "3D Scanning",
      "CAD/CAM Designing",
      "Reverse Engineering",
      "Simulation and Analysis services",
    ],
    "Materials Engineering Services": [
      "Mechanical Testing",
      "Thermal Testing",
      "Electrical Testing",
      "Chemical Testing",
      "Optical & Spectral services",
      "Acoustic Testing",
    ],
    "Non Destructive Services": [
      "Nano and MicroCT Analysis",
      "Ultrasonic Testing (UT)",
      "Radiographic Testing (RT)",
      "Magnetic Particle Testing (MT)",
      "Dye Penetrant Testing (PT)",
      "Eddy Current Testing (ET)",
      "Visual Inspection",
      "Thermography",
    ],
    "Functional Services": [
      "Virtual / Mechanical Simulation",
      "Digital Simulation",
      "Reliability",
      "Fire and Safety",
      "Environmental Testings",
    ],
    "Manufacturing Services": [
      "E2E Manufacturing & Projects",
      "Subassemblies & Components",
      "Machining work",
      "Storage and Fabrication services",
    ],
  };


  const [openSection, setOpenSection] = useState(null);
  const containerRef = useRef(null);



  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpenSection(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className='maincontainer p-20' ref={containerRef}>
      <div className='Scrolling-Servicetext-container w-full'>
      <motion.div
        className='service-text '
        animate={{ x: ['0%', '-100%'] }}
        transition={{ duration: 30, ease: 'linear', repeat: Infinity }}
      >
        <span>Services</span>
        <span>Services</span>
        <span>Services</span>
        <span>Services</span>
        <span>Services</span>
        <span>Services</span>
        <span>Services</span>
      </motion.div>
         </div>
        
        <div className='searchbox'>
            <p>Our Testing services cover nearly 100% of all your needs</p>
        </div>
        <div className='service-container gap-28 justify-center'>
          
          <div className='max-w-96 left-container-text outline outline-slate-400  outline-1 p-6 rounded backdrop-blur-md'>
          <h1 className='text-4xl  text-center font-medium'>
          Industrial Testing<br/>
          &<br/>
          Manufacturing<br/> 
          Solutions
          </h1>
            </div>
         
            <div className='right-service-container max-w-96'>
            <ServiceSection
            services={services}
            openSection={openSection}
            handleToggle={handleToggle}
          />
        </div>
        </div>
    </div>
  )
}

export default Services




