// import React, { useState } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

// const ProductDetails = () => {
//   const [expandedSection, setExpandedSection] = useState(null);

//   const toggleSection = (section) => {
//     setExpandedSection(expandedSection === section ? null : section);
//   };

//   const renderSectionContent = (content) => (
//     <motion.div
//       initial={{ opacity: 0, height: 0 }}
//       animate={{ opacity: 1, height: 'auto' }}
//       exit={{ opacity: 0, height: 0 }}
//       transition={{ duration: 0.5, ease: 'easeInOut' }}
//       className="details-content"
//     >
//       {content}
//     </motion.div>
//   );

//   return (
//     <div className="product-details">
//       <h2 className="section-title" onClick={() => toggleSection('technicalDetails')}>
//         Technical Details {' '}
//         <FontAwesomeIcon icon={expandedSection === 'technicalDetails' ? faChevronUp : faChevronDown} className="toggle-icon" />
//       </h2>
//       <AnimatePresence>
//         {expandedSection === 'technicalDetails' && renderSectionContent(
//           <div className="details-section">
//             <p className="detail-item"><strong>Make:</strong></p>
//             <p className="detail-item"><strong>Model:</strong></p>
//             <p className="detail-item"><strong>Series:</strong></p>
//             <p className="detail-item"><strong>Version:</strong></p>
//             <p className="detail-item"><strong>Origin Country:</strong></p>
//             <p className="detail-item"><strong>Product Dimensions:</strong></p>
//             <p className="detail-item"><strong>Product Weight:</strong></p>
//             <p className="detail-item"><strong>HSN / SAC Code:</strong></p>
//             <p className="detail-item"><strong>Warranty:</strong></p>
//             <p className="detail-item"><strong>Delivery Time:</strong></p>
//             <p className="detail-item"><strong>Product Demonstration:</strong> Available Online / Offline</p>
//             <p className="detail-item"><strong>Product Training:</strong></p>
//             <p className="detail-item"><strong>Required Skillset:</strong></p>
//           </div>
//         )}
//       </AnimatePresence>

//       <h2 className="section-title" onClick={() => toggleSection('prerequisites')}>
//         Pre-requisites {' '}
//         <FontAwesomeIcon icon={expandedSection === 'prerequisites' ? faChevronUp : faChevronDown} className="toggle-icon" />
//       </h2>
//       <AnimatePresence>
//         {expandedSection === 'prerequisites' && renderSectionContent(
//           <div className="details-section">
//             <p className="detail-item"><strong>Site Preparation:</strong></p>
//             <p className="detail-item"><strong>Power Sockets:</strong></p>
//             <p className="detail-item"><strong>System / Software Including OS:</strong></p>
//             <p className="detail-item"><strong>IOT Status:</strong> Ready / Not Compatible</p>
//             <p className="detail-item"><strong>AI/ML Ready:</strong> -NA-</p>
//             <p className="detail-item"><strong>Operator Training:</strong></p>
//             <p className="detail-item"><strong>Any Approvals:</strong></p>
//           </div>
//         )}
//       </AnimatePresence>

//       <h2 className="section-title" onClick={() => toggleSection('otherInformation')}>
//         Other Information {' '}
//         <FontAwesomeIcon icon={expandedSection === 'otherInformation' ? faChevronUp : faChevronDown} className="toggle-icon" />
//       </h2>
//       <AnimatePresence>
//         {expandedSection === 'otherInformation' && renderSectionContent(
//           <div className="details-section">
//             <p className="detail-item"><strong>AMC / CMC:</strong> Applicable / -NA-</p>
//             <p className="detail-item"><strong>AMC / CMC Charges:</strong></p>
//           </div>
//         )}
//       </AnimatePresence>

//       <h2 className="section-title" onClick={() => toggleSection('buyingMechanism')}>
//         Buying Mechanism {' '}
//         <FontAwesomeIcon icon={expandedSection === 'buyingMechanism' ? faChevronUp : faChevronDown} className="toggle-icon" />
//       </h2>
//       <AnimatePresence>
//         {expandedSection === 'buyingMechanism' && renderSectionContent(
//           <div className="details-section">
//             <p className="detail-item"><strong>Ordering Method:</strong></p>
//             <p className="detail-item"><strong>Delivery Mode:</strong></p>
//             <p className="detail-item"><strong>Gross Weight:</strong></p>
//             <p className="detail-item"><strong>Apply for pre-site inspection:</strong> Applicable / Not Applicable (link will be active after purchasing the product)</p>
//           </div>
//         )}
//       </AnimatePresence>

//       <h2 className="section-title" onClick={() => toggleSection('itemsInBox')}>
//         Items In the Box {' '}
//         <FontAwesomeIcon icon={expandedSection === 'itemsInBox' ? faChevronUp : faChevronDown} className="toggle-icon" />
//       </h2>
//       <AnimatePresence>
//         {expandedSection === 'itemsInBox' && renderSectionContent(
//           <div className="details-section">
//             <p className="detail-item"><strong>Actual Product:</strong></p>
//             <p className="detail-item"><strong>User Manual:</strong></p>
//             <p className="detail-item"><strong>Technical Specification Booklet:</strong></p>
//           </div>
//         )}
//       </AnimatePresence>

//       <h2 className="section-title" onClick={() => toggleSection('additionalPurchases')}>
//         Additional / Optional Purchases {' '}
//         <FontAwesomeIcon icon={expandedSection === 'additionalPurchases' ? faChevronUp : faChevronDown} className="toggle-icon" />
//       </h2>
//       <AnimatePresence>
//         {expandedSection === 'additionalPurchases' && renderSectionContent(
//           <div className="details-section">
//             <p className="detail-item"><strong>Softwares:</strong></p>
//             <p className="detail-item"><strong>Hardwares:</strong></p>
//           </div>
//         )}
//       </AnimatePresence>

//       <h2 className="section-title" onClick={() => toggleSection('downloads')}>
//         Downloads {' '}
//         <FontAwesomeIcon icon={expandedSection === 'downloads' ? faChevronUp : faChevronDown} className="toggle-icon" />
//       </h2>
//       <AnimatePresence>
//         {expandedSection === 'downloads' && renderSectionContent(
//           <div className="details-section">
//             <p className="detail-item"><strong>Product Brochure:</strong></p>
//             <p className="detail-item"><strong>Product Images:</strong></p>
//             <p className="detail-item"><strong>Technical Specifications:</strong></p>
//           </div>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// export default ProductDetails;







import React from 'react';
import './ProductDetails.css'

const ProductDetails = () => {
  return (
    <div className="product-details">
      <div className='Technical-Details-section'>
        <h2 className="section-title text-black">Technical Details</h2><br/>
        <div className="details-section">
          <p className="detail-item"><strong>Make:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Model:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Series:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Version:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Origin Country:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Product Dimensions:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Product Weight:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>HSN / SAC Code:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Warranty:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Delivery Time:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Product Demonstration:</strong> Available Online / Offline <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Product Training:</strong> <span className="underline-content"></span></p>
          <p className="detail-item"><strong>Required Skillset:</strong> <span className="underline-content"></span></p>
        </div>
      </div>


      <div className='Pre-requisites-section'>
      <h2 className="section-title text-black">Pre-requisites</h2>
      <div className="details-section">
        <p className="detail-item"><strong>Site Preparation:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Power Sockets:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Power Backup / UPS:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>System / Software Including OS:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>IOT Status:</strong> Ready / Not Compatible <span className="underline-content"></span></p>
        <p className="detail-item"><strong>AI/ML Ready:</strong> -NA- <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Operator Training:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Any Approvals:</strong> <span className="underline-content"></span></p>
      </div>
      </div>

      <div className='Other-Information-section'>
      <h2 className="section-title text-black">Other Information</h2>
      <div className="details-section">
        <p className="detail-item"><strong>AMC / CMC:</strong> Applicable / -NA- <span className="underline-content"></span></p>
        <p className="detail-item"><strong>AMC / CMC Charges:</strong> <span className="underline-content"></span></p>
      </div>
      </div>


      <div className='Buying-Mechanism-section'>
      <h2 className="section-title text-black">Buying Mechanism</h2>
      <div className="details-section">
        <p className="detail-item"><strong>Ordering Method:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Delivery Mode:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Gross Weight:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Apply for pre-site inspection:</strong> Applicable / Not Applicable (link will be active after purchasing the product) <span className="underline-content"></span></p>
      </div>
      </div>

      <div className='Items-section'>
      <h2 className="section-title text-black">Items In the Box</h2>
      <div className="details-section">
        <p className="detail-item"><strong>Actual Product:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>User Manual:</strong> <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Technical Specification booklet:</strong> <span className="underline-content"></span></p>
      </div>
      </div>

        <div className='Additional-OptionalPurchases-section'>
      <h2 className="section-title text-black">Additional / Optional Purchases</h2>
      <div className="details-section">
        <p className="detail-item"><strong>Softwares:</strong> List with config <span className="underline-content"></span></p>
        <p className="detail-item"><strong>Hardwares:</strong> List with config <span className="underline-content"></span></p>
      </div>
      </div>


      <div className='Downloads-section-container'>
      <h2 className="section-title text-black">Downloads</h2>
      <div className='Downloads-section'>
         <div className='Brochure-section'>
          <h3>Product Brochure</h3><br/>
            <button className='border-slate-800 border text-black'>
              Download
            </button>
         </div>
         <div className='Product-Images-section'>
         <h3>Product Images</h3><br/>
         <button className='border-slate-800 border text-black'>
              Download
            </button>
         </div>
         <div className='Technical-Specifications-section'>
         <h3>Technical Specifications</h3><br/>
         <button className='border-slate-800 border text-black'>
              Download
            </button>
         </div>
         </div>
      </div>
    </div>
  );
};

export default ProductDetails;
