import React from 'react'
import './ProductsCategories.css'
import Navbar from '../Navbar'
import { useParams } from 'react-router-dom'
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import ProductCategoryStatus from './ProductCategoryStatus/ProductCategoryStatus';
import PrinterImg from '../../assets/Printer.png'
import AdsImage1 from '../../assets/ProductCategoryAdsImg1.png';
import AdsImage2 from '../../assets/ProductCategoryAdsImg2.png';
import AdsImage3 from '../../assets/ProductCategoryAdsImg3.png';
import AdsImage4 from '../../assets/ProductCategoryAdsImg4.png';
import AdsImage5 from '../../assets/ProductCategoryAdsImg5.png';
import HammerImg from '../../assets/Hammer.png';
import XRayTubeImg from '../../assets/X-Raytube.png';
import ProductCard from '../Cards/ProductCard';
import AdsSlider from '../AdsSlider/AdsSlider';

function ProductsCategories() {
  const Adsimages = [
    { src: AdsImage1 },
    { src: AdsImage2 },
    { src: AdsImage3 },
    { src: AdsImage4 },
    { src: AdsImage5 }
  ];
    const { category } = useParams(); 
    

//     const images = [PrinterImg, HammerImg, XRayTubeImg];

//     const Productcards = [];
// let productCounter = 1; // Initialize counter for sequential titles

// // Populate Productcards array
// for (let i = 0; i < 9; i++) { // Assuming 9 cards for demonstration
//   const imageIndex = i % images.length;
//   Productcards.push({
//     title: `Card ${i + 1}`, // Card titles will be used to match category
//     images: Array(3).fill(null).map(() => ({
//       id: productCounter++, // Increment counter for each product
//       image: images[imageIndex],
//       price: `$${(i + 1) * 10}`,
//       content: `Description of card ${i + 1}`
//     }))
//   });
// }

// const filteredProducts = Productcards.find(product => product.title === decodeURIComponent(category));



const cards = [
  { id: 1, title: '3D Printing Systems', content: 'Content for card 1' },
  { id: 2, title: '3D Scanning Systems', content: 'Content for card 2' },
  { id: 3, title: 'Manufacturing Systems', content: 'Content for card 3' },
  { id: 4, title: 'Metallurgical Systems', content: 'Content for card 4' },
  { id: 5, title: 'Supporting Devices', content: 'Content for card 5' },
  { id: 6, title: '3D Design Tools', content: 'Content for card 6' },
  { id: 7, title: 'Hand Tools', content: 'Content for card 7' },
  { id: 8, title: 'Inspection Tools', content: 'Content for card 8' },
  { id: 9, title: 'IOT 4.0 Tools', content: 'Content for card 9' },
  { id: 10, title: 'Card 10', content: 'Content for card 10' }
];

const images = [PrinterImg, HammerImg, XRayTubeImg];

const Productcards = cards.map((card, index) => {
  const imageIndex = index % images.length;
  return {
    ...card,
    images: Array(3).fill(null).map(() => ({
      id: card.id,
      image: images[imageIndex],
      price: `$${(index + 1) * 10}`,
      content: `Description of ${card.title.toLowerCase()}`
    }))
  };
});

const filteredProducts = Productcards.find(product => product.title === decodeURIComponent(category));

  return (
    <div className='ProductsCategoriesmain-container'>
        <Navbar/>
        <div className='ProductsText-container'>
         <h1>{decodeURIComponent(category)}</h1>
         <hr style={{width:'60%', textAlign:'left', marginTop:'1em'}}/>
        </div>
        <br/>
        <div className="dropdown-container">
            <div className='dropdown-left'>
        <DropDownMenu
          label="Origin"
          options={['Origin 1', 'Origin 2', 'Origin 3']}
        />
        <DropDownMenu
          label="Brand"
          options={['Brand 1', 'Brand 2', 'Brand 3']}
        />
        <DropDownMenu
          label="Color"
          options={['Color 1', 'Color 2', 'Color 3']}
        />
        </div>
        <div className='dropdown-right'>
        <DropDownMenu
          label="Newest"
          options={['Newest 1', 'Newest 2', 'Newest 3']}
        />
        </div>
      </div>

      <div className='ProductsCategory-Cards'>
      {/* {filteredProducts && filteredProducts.images.map((product, index) => (
          <div className='ProductCardWrapper' key={product.id}>
            <ProductCard
              image={product.image}
              id={product.id}
              title={`Product ${product.id}`} // Adjust title format if needed
              price={product.price}
              content={product.content}
            />
          </div>
        ))} */}
          {filteredProducts && filteredProducts.images.map((product, index) => (
          <div className='ProductCardWrapper' key={product.id}>
            <ProductCard
              image={product.image}
              id={product.id}
              title={`Product ${product.id}`}
              price={product.price}
              content={product.content}
            />
          </div>
        ))}
      </div>
        
        <div className='ProductsCategories-ads-container'>
        <AdsSlider images={ Adsimages}/>
        </div>

        <div className='Recentlyviewed-container'>
        <h2>Dynamically displaying products which is recently viewed based on category</h2>
        </div>
            <br/>
        <div>
            <ProductCategoryStatus systemsSold={20}  selectionRate={8.5}  reviews={4.5} trustedPartners={100}/>
        </div>
    </div>
  )
}

export default ProductsCategories