import React from 'react'
import './Navbar.css'
import CovetenLogo from '../assets/COVETEN LoGo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { Link , useLocation} from 'react-router-dom'
import Heading from './Headings/Heading';

function Navbar() {
  const location = useLocation();
  return (
  <div className="navbar">
  <div className="logo">
    <img src={CovetenLogo} alt="Logo" className="logo-img" />
  </div>
  <nav className='Navlink-container'>
  <Link to="/" className={location.pathname === '/' ? 'active' : ''}><Heading as="h4">What we do</Heading></Link>
        <Link to="/products" className={location.pathname === '/products' ? 'active' : ''}><Heading as="h4">Products</Heading></Link>
        <Link to="/insights" className={location.pathname === '/insights' ? 'active' : ''}><Heading as="h4">Insights</Heading></Link>
        <Link to="/benefits" className={location.pathname === '/benefits' ? 'active' : ''}><Heading as="h4">Benefits</Heading></Link>
  </nav>
  <div className="login-signup-container">
    <button className='login-signup-button'>
    <FontAwesomeIcon icon={faRightToBracket} className='fa-2x'/> 
      </button>
  </div>
</div>
  )
}

export default Navbar