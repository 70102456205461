// import React from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import "./CardSlider.css";

// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//     slidesToSlide: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 768 },
//     items: 2,
//     slidesToSlide: 1,
//   },
//   mobile: {
//     breakpoint: { max: 767, min: 464 },
//     items: 1,
//     slidesToSlide: 1,
//   },
// };


// const CustomLeftArrow = ({ onClick }) => {
//   return (
//     <button className="custom-arrow custom-left-arrow" onClick={onClick}>
//       &#8592;
//     </button>
//   );
// };

// // Custom Right Arrow
// const CustomRightArrow = ({ onClick }) => {
//   return (
//     <button className="custom-arrow custom-right-arrow" onClick={onClick}>
//       &#8594;
//     </button>
//   );
// };

// const cardContent = [
//   {
//     heading: "Manufacturing Services",
//     subcontent: [
//       "Component Designing & Fabrication",
//       "World Class Industrial Testings",
//       "Material Sourcing",
//       "Assembling and Fabrication",
//     ],
//   },
//   {
//     heading: "Our Advanced Technologies",
//     subcontent: [
//       "AR/VR Enabled Solutions",
//       "3D & Design Solutions",
//       "Cloud Solutions & IoT tools",
//       "Best in class dashboards & Analytic tools",
//     ],
//   },
//   {
//     heading: "Marketing Services",
//     subcontent: [
//       "Go to market Strategies",
//       "Market research & analysis",
//       "Customer analysis",
//       "Channel Management",
//       "Marketing Optimisation",
//       "Product performance",
//     ],
//   },
//   {
//     heading: "Our Industrial Benefits",
//     subcontent: [
//       "Foods Cards",
//       "Specialised Offers",
//       "Excited travel benefits",
//       "Truly good medical support",
//       "Advanced trainings and certifications",
//       "Best in knowledge transfer",
//       "Building Sustainable solutions",
//     ],
//   },
//   {
//     heading: "Specialised Marketing Services",
//     subcontent: [
//       "Fact Checks",
//       "Problems analysis and solving",
//       "Dealer and distributors identification",
//       "Strategic planning",
//     ],
//   },
//   {
//     heading: "Consulting Services",
//     subcontent: [
//       "Operations consulting",
//       "Management consulting",
//       "Strategy consulting",
//       "IT consulting",
//     ],
//   },
//   {
//     heading: "Our Industry Specializations",
//     subcontent: [
//       "Semi Conductors Manufacturing",
//       "Electronic Components & Sensors",
//       "Consumable Medical products",
//       "Advanced Materials & Components",
//       "Safety Suites & Gears",
//     ],
//   },
//   {
//     heading: "Our Efficiency",
//     subcontent: [
//       "Strongest Local teams",
//       "Widest Marketing network",
//       "Faster & Efficient deployment",
//       "Diversified portfolios",
//       "Multilevel tasking",
//     ],
//   },
//   {
//     heading: "Our Industrial Focus",
//     subcontent: [
//       "Electronics & Semiconductors",
//       "Advanced Aviation",
//       "Superalloys",
//       "Composites and Reusable materials",
//       "Sustainable technologies and EV’s",
//       "Renewable energies",
//       "Super foods",
//       "Modern Medicine & Healthcare",
//     ],
//   },
// ];

// const CardSlider = () => {
//   return (
//     <div className="card-parent">
//       <Carousel
//         responsive={responsive}
//         autoPlay={true}
//         swipeable={true}
//         draggable={true}
//         showDots={false}
//         infinite={true}
//         partialVisible={false}
//         customLeftArrow={<CustomLeftArrow />}
//         customRightArrow={<CustomRightArrow />}
//         dotListClass="custom-dot-list-style"
//       >
//         {cardContent.map((content, index) => {
//           return (
//             <div className="card-slider" key={index}>
//               <h3>{content.heading}</h3>
//               <ul>
//                 {content.subcontent.map((item, subIndex) => (
//                   <li key={subIndex}>{item}</li>
//                 ))}
//               </ul>
//             </div>
//           );
//         })}
//       </Carousel>
//     </div>
//   );
// };

// export default CardSlider;









import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./CardSlider.css";

// Define responsive breakpoints
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
};


// Custom Left Arrow
const CustomLeftArrow = (props) => (
  <button
    className="custom-arrow custom-left-arrow"
    onClick={props.onClick}
  >
    <FontAwesomeIcon icon={faArrowLeft} />
  </button>
);

// Custom Right Arrow
const CustomRightArrow = (props) => (
  <button
    className="custom-arrow custom-right-arrow"
    onClick={props.onClick}
  >
    <FontAwesomeIcon icon={faArrowRight} />
  </button>
);

const cardContent = [
  {
    heading: "Manufacturing Services",
    subcontent: [
      "Component Designing & Fabrication",
      "World Class Industrial Testings",
      "Material Sourcing",
      "Assembling and Fabrication",
    ],
  },
  {
    heading: "Our Advanced Technologies",
    subcontent: [
      "AR/VR Enabled Solutions",
      "3D & Design Solutions",
      "Cloud Solutions & IoT tools",
      "Best in class Dashboards & Analytic tools",
    ],
  },
  {
    heading: "Marketing Services",
    subcontent: [
      "Go to market Strategies",
      "Market research & analysis",
      "Customer analysis",
      "Channel Management",
      "Marketing Optimisation",
      "Product performance",
      "Multileval marketing"
    ],
  },
  {
    heading: "Our Industrial Benefits",
    subcontent: [
      "Foods Cards",
      "Specialised Offers",
      "Excited travel benefits",
      "Truly good medical support",
      "Advanced trainings and certifications",
      "knowledge centers & Live trainings",
      "Building Sustainable solutions",
    ],
  },
  {
    heading: "Specialised Marketing Services",
    subcontent: [
      "Fact Checks",
      "Problems analysis and solving",
      "Dealer and distributors networking",
      "Strategic planning",
    ],
  },
  {
    heading: "Consulting Services",
    subcontent: [
      "Operations consulting",
      "Management consulting",
      "Strategy consulting",
      "IT consulting",
    ],
  },
  {
    heading: "Our Industry Specializations",
    subcontent: [
      "Semi Conductors Manufacturing",
      "Electronic Components & Sensors",
      "Consumable Medical products",
      "Advanced Materials & Components",
      "Safety Suites & Gears",
    ],
  },
  {
    heading: "Our Efficiency",
    subcontent: [
      "Strongest Local teams",
      "Widest Marketing network",
      "Faster & Efficient deployment",
      "Diversified portfolios",
      "Multilevel tasking",
    ],
  },
  {
    heading: "Our Industrial Focus",
    subcontent: [
      "Electronics & Semiconductors",
      "Advanced Aviation",
      "Superalloys",
      "Composites and Reusable materials",
      "Sustainable technologies and EV’s",
      "Renewable energies",
      "Super foods",
      "Modern Medicine & Healthcare",
    ],
  },
];


const CardSlider = () => {
  return (
    <div className="card-parent">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        partialVisible={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        dotListClass="custom-dot-list-style"
      >
        {cardContent.map((content, index) => (
          <div className="card-slider" key={index}>
           <div className="w-full min-h-28 max-h-28 overflow-hidden p-6 bg-[#585858] flex items-center">
            <h3 className="text-white">{content.heading}</h3>
            </div> 
            <div className="py-5 px-7">
            <ul className="list">
              {content.subcontent.map((item, subIndex) => (
                <li key={subIndex}>{item}</li>
              ))}
            </ul>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CardSlider;
