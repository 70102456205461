import React from 'react'
import './Benefits.css'
import BenefitPageContent from '../components/BenefitPageContent/BenefitPageContent'
// import LeadsImg from '../assets/Leads.png'
// import Navbar from '../components/Navbar'
// import Experts from '../components/Experts/Experts'
// import Homepages from '../components/BenefitsContent/Homepages'



function Benefits() {
  return (
      
      <div className='w-full h-full pt-28 pb-16 bg-white'>
        <BenefitPageContent/>
          {/* <Homepages/> */}
        {/* <Experts/> */}
     {/* <div className='Benefitsmain-container'> */}
        {/* <Navbar/> */}
        
       {/* <div className='Benefits-part-container'>
       <div className='Benefits-content1'>
      <Homepages/>
        </div> */}
      
        {/* <div className='Benefits-part3'>
          <h2>What we get in  services as members</h2>
          <div className='Benefits-content'>
            <p>100 % Assistance, Assurance, Information,</p>
            <p>100% Traceable and Trackable, genuine</p>
            <p>Standardised reports, Best price and World class</p>
          </div>
        </div> */}

{/* <div className='experts-container'>
<Experts/>
</div> */}

        
        
        {/* </div> */}
        </div>
  )
}

export default Benefits