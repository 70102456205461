import React from 'react';
import './NewsTicker.css';

const NewsTicker = ({ newsItems }) => {
  return (
    <section className="flex items-center news-ticker pb-5">
      <div>
        <h3 className="px-2 bg-red-600 w-48 text-white text-center text-lg">TRENDING NOW</h3>
      </div>
      <div className="news overflow-hidden h-12">
        <div className="news-content animate-slide">
          {newsItems.map((item, index) => (
            <span key={index} className="block h-12 py-3 px-4 text-black">
              {item}
            </span>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsTicker;
