// import React from 'react'
// import {Carousel} from 'react-responsive-carousel'
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import img1 from '../assets/Img1.png'
// import img2 from '../assets/Img2.jpg'
// import img3 from '../assets/Img3.jpg'
// import './Slider.css'


// function Slider() {
//     const images = [img1, img2, img3];

//   return (
//     <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} showStatus={false} showIndicators={false} >
//       {images.map((image, index) => (
//         <div key={index} style={{height:'35em'}}>
//           <img src={image}
//             alt={`Slide ${index + 1}`}
//             style={{width: '100%', height: '100%', objectFit: 'cover' }}  />
//         </div>
//       ))}
//     </Carousel>
//   )
// }

// export default Slider



import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from '../assets/Img1.png';
import img2 from '../assets/Img2.jpg';
import img3 from '../assets/Img3.jpg';
import img4 from '../assets/christopher-burns-8KfCR12oeUM-unsplash.jpg'
import img5 from '../assets/jakub-zerdzicki-MtO8S9yazeU-unsplash.jpg'
import img6 from '../assets/jakub-zerdzicki-sKhzb1uyBDU-unsplash.jpg'

import './Slider.css';

function Slider() {
  const images = [
    { src: img4, text: 'Ideas into Reality' },
    { src: img5, text: 'Simplified Manufacturing' },
    { src: img3, text: 'Transforming Future' },
  ];

  return (
    <Carousel
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      showStatus={false}
      showIndicators={false}
    >
      {images.map((image, index) => (
        <div key={index} style={{ position: 'relative', height: '35em' }}>
          <img
            src={image.src}
            alt={`Slide ${index + 1}`}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <div className="carousel-text">
            {image.text}
          </div>
        </div>
      ))}
    </Carousel>
  );
}

export default Slider;
