// ProductCategoryStatus.js
import React from 'react';
import './ProductCategoryStatus.css';

function ProductCategoryStatus({ systemsSold, selectionRate, reviews, trustedPartners }) {
  return (
    <div className='status-bar'>
      <div className='status-item'>
        <div className='status-value'>{systemsSold}</div>
        <div className='status-label'>Systems Sold</div>
      </div>
      <div className='separator'></div>
      <div className='status-item'>
        <div className='status-value'>{selectionRate}</div>
        <div className='status-label'>Selection Rate</div>
      </div>
      <div className='separator'></div>
      <div className='status-item'>
        <div className='status-value'>{reviews}</div>
        <div className='status-label'>Reviews</div>
      </div>
      <div className='separator'></div>
      <div className='status-item'>
        <div className='status-value'>{trustedPartners}</div>
        <div className='status-label'>Trusted Partners</div>
      </div>
    </div>
  );
}

export default ProductCategoryStatus;
