// import React from 'react'
// import { useNavigate } from 'react-router-dom'
// import './Card.css'
// function Card({title, content, onClick }) {
//   const navigate = useNavigate(); 

//   const handleClick = () => {
//     // Navigate to ProductsCategories with the card title as a parameter
//     navigate(`/products/product-categories/${encodeURIComponent(title)}`);
//   };

//   return (
//     <div className="card" onClick={handleClick}>
//       <h3>{title}</h3>
//       <p>{content}</p>
//       <button className="know-more-button">Know More</button>
//     </div>
//   )
// }

// export default Card


import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Card.css';

function Card({ title, content }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/products/product-categories/${encodeURIComponent(title)}`);
  };

  return (
    <motion.div
      className="solution_card"
      onClick={handleClick}
      whileHover={{ scale: 1.1, backgroundColor: "#309df0", color: "#fff" }}
      transition={{ duration: 0.7 }}
    >
      <div className="solu_title">
        <h3 className='text-black'>{title}</h3>
      </div>
      <div className="solu_description">
        <p>{content}</p>
        <button>Explore</button>
      </div>
      <div className="hover_color_bubble"></div>
    </motion.div>
  );
}

export default Card;

