// import React from 'react'
// import './Footer.css'
// import Logo from '../../assets/CovetenLogo.png'
// import InstaLogo from '../../assets/instagram-logo.png'
// import LinkedInLogo from '../../assets/linkedin.png'
// import PinterestLogo from '../../assets/pinterest.png'
// import TwitterLogo from '../../assets/twitter.png'
// import YoutubeLogo from '../../assets/youtube.png'
// import Facebook from '../../assets/facebook.png'
// import { Link } from 'react-router-dom'

// function Footer() {
//   return (
//     <div className='Footer-Maincontainer'>
//         <div className='Footer-Subcontainer'>
//             <div className='Footer-Left'>
//             <img src={Logo} alt='logo' className="footer-logo"/>
//             <div className='socialicons-container'>
//                  <img src={Facebook} alt='facebook'/> 
//                  <img src={TwitterLogo} alt='twitter'/> 
//                  <img src={InstaLogo} alt='instagram'/> 
//                  <img src={PinterestLogo} alt='pinterest'/> 
//                  <img src={YoutubeLogo} alt='youtube'/> 
//                  <img src={LinkedInLogo} alt='linkedIn'/> 
//             </div>
//              </div> 
//              <div className='Rights'>
//               <p>Design And Developed By Coveten Team</p>
//               <p>2024 Coveten Technologies . All Rights Reserved</p>
//              </div>
//             <div className='Footer-Right'>
//                 <div className='section'>
//                 <Link to="#">Disclaimer</Link>
//                   <Link to="#">cookies policy</Link>
//                 </div>
//             </div> 
//         </div>
//     </div>
//   )
// }

// export default Footer








import React from 'react';
import './Footer.css';
import Logo from '../../assets/COVETEN LoGo.png';
import { Link } from 'react-router-dom';


function Footer() {
 

  return (
    
    //   <div className='Footer-Subcontainer bg-black full-width px-16 pt-10 pb-16 z-50'>
    //     <div className='Footer-Left'>
    //       <img src={Logo} alt='logo' className="footer-logo" />
    //       <div className='socialicons-container'>
    //       <a
    //       href='https://www.facebook.com/profile.php?id=61553710623584&mibextid=ZbWKwL'
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       className='social-icon'
    //     >
    //       <i className='fa-brands fa-facebook'></i>
    //     </a>


    //     <a
    //       href='https://twitter.com/Xcoveten'
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       className='social-icon'
    //     >
    //      <i class="fa-brands fa-twitter"></i>
    //     </a>


    //     <a
    //       href='https://www.instagram.com/coveten_technologies_india?igsh=b2xvZHV2MmZnYmF4'
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       className='social-icon'
    //     >
    //     <i class="fa-brands fa-instagram"></i>
    //     </a>

    //     <a
    //       href='https://in.pinterest.com/pcoveten/'
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       className='social-icon'
    //     >
    //    <i class="fa-brands fa-pinterest"></i>
    //     </a>

    //     <a
    //       href='https://www.youtube.com/@Coveten'
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       className='social-icon'
    //     >
    //   <i class="fa-brands fa-youtube"></i>
    //     </a>

    //     <a
    //       href='https://www.linkedin.com/company/coveten-industrial-solutions/'
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       className='social-icon'
    //     >
    //  <i class="fa-brands fa-linkedin-in"></i>
    //     </a>
    //       </div>
    //     </div>
    //     <div className='Rights'>
    //       <p>Design And Developed By Coveten Team  | 2024 Coveten Technologies . All Rights Reserved</p>
    //     </div>
    //     <div className='Footer-Right'>
    //     <div className='section'>
    //         <Link to="#" className='footer-link'>Disclaimer</Link>
    //         <Link to="#" className='footer-link'>Cookies Policy</Link>
    //       </div>
    //     </div> 
    //   </div>
    <div className='w-full flex items-center justify-center gap-8 p-10 z-10 bg-black'>
      <div className='w-1/2 flex flex-col items-start'>
          <div>
          <img src={Logo} alt='logo' className="footer-logo" />
          </div>
          <div className='flex items-center justify-center gap-5 text-center'>
          <a
          href='https://www.facebook.com/profile.php?id=61553710623584&mibextid=ZbWKwL'
          target='_blank'
          rel='noopener noreferrer'
          className='social-icon'
        >
          <i className='fa-brands fa-facebook'></i>
        </a>


        <a
          href='https://twitter.com/Xcoveten'
          target='_blank'
          rel='noopener noreferrer'
          className='social-icon'
        >
         <i class="fa-brands fa-twitter"></i>
        </a>


        <a
          href='https://www.instagram.com/coveten_technologies_india?igsh=b2xvZHV2MmZnYmF4'
          target='_blank'
          rel='noopener noreferrer'
          className='social-icon'
        >
        <i class="fa-brands fa-instagram"></i>
        </a>

        <a
          href='https://in.pinterest.com/pcoveten/'
          target='_blank'
          rel='noopener noreferrer'
          className='social-icon'
        >
       <i class="fa-brands fa-pinterest"></i>
        </a>

        <a
          href='https://www.youtube.com/@Coveten'
          target='_blank'
          rel='noopener noreferrer'
          className='social-icon'
        >
      <i class="fa-brands fa-youtube"></i>
        </a>

        <a
          href='https://www.linkedin.com/company/coveten-industrial-solutions/'
          target='_blank'
          rel='noopener noreferrer'
          className='social-icon'
        >
     <i class="fa-brands fa-linkedin-in"></i>
        </a>
          </div>
      </div>
      <div className='flex flex-col w-full items-center justify-end pt-10'>
    <div className='flex w-full gap-4 items-center justify-center'>
      <Link to="#" className='footer-link'>Disclaimer</Link>
      <Link to="#" className='footer-link'>Cookies Policy</Link>
    </div>
    <hr className='my-4 border-gray-600 w-full' />
    <p className='text-center text-gray-400 text-sm'>
      Design And Developed By Coveten Team | 2024  Coveten Technologies . All Rights Reserved
    </p>
  </div>
    </div>
  );
}

export default Footer;
