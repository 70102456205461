import React, { useEffect, useRef } from 'react';
import './Products.css';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
import Card from '../components/Cards/Card';
// import ProductVedio from '../assets/localassets/ProductsVedio.mp4'
import AdsImage1 from '../assets/ProductAdsImg1.png';
import AdsImage2 from '../assets/ProductAdsImg2.png';
import AdsImage3 from '../assets/ProductAdsImg3.png';
import AdsImage4 from '../assets/ProductAdsImg4.png';
import AdsImage5 from '../assets/ProductAdsImg5.png';
import PrinterImg from '../assets/Printer.png';
import HammerImg from '../assets/Hammer.png';
import XRayTubeImg from '../assets/X-Raytube.png';
import { useInView } from 'react-intersection-observer';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductCard from '../components/Cards/ProductCard';
import AdsSlider from '../components/AdsSlider/AdsSlider';
import VerticalCarousel from '../components/Animation/VerticalCarousel';

function Products() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
 

  const Adsimages = [
    { src: AdsImage1 },
    { src: AdsImage2 },
    { src: AdsImage3 },
    { src: AdsImage4 },
    { src: AdsImage5 }
  ];

  
  const cards = [
    { id: 1, title: '3D Printing Systems', content: 'Content for card 1' },
    { id: 2, title: '3D Scanners', content: 'Content for card 2' },
    { id: 3, title: 'Manufacturing Systems', content: 'Content for card 3' },
    { id: 4, title: 'Metallurgical Systems', content: 'Content for card 4' },
    { id: 5, title: 'Supporting Devices', content: 'Content for card 5' },
    { id: 6, title: '3D Design Tools', content: 'Content for card 6' },
    { id: 7, title: 'Hand Tools', content: 'Content for card 7' },
    { id: 8, title: 'Inspection Tools', content: 'Content for card 8' },
    { id: 9, title: 'IOT 4.0 Tools', content: 'Content for card 9' },
    { id: 10, title: 'Fabrications', content: 'Content for card 10' },
  ];

 

  

  const images = [PrinterImg, HammerImg, XRayTubeImg];


  const ProductCards=[
    {id:1 ,image:PrinterImg , title:'Printer', price:'$120', content:'Description of Printer'},
    {id:2 ,image:HammerImg , title:'Hammer', price:'$120', content:'Description of Printer'},
    {id:3 ,image:XRayTubeImg , title:'XRayTube', price:'$120', content:'Description of Printer'},
    {id:4 ,image:PrinterImg , title:'Hammer Lab 78', price:'$120', content:'Description of Printer'},
  ]


  return (
    <div className='w-full bg-white'>
    {/* <div className='w-full h-full'>
    <video className="w-full" playsinline autoPlay loop muted>
          <source src={ProductVedio} type="video/mp4"/>
        </video>
    </div>
       */}
       <div className='w-full h-full'>
       <Slider />
       </div>
    <div className='grid grid-cols-5 p-20'>
    {cards.map((card) => (
          <Card
            key={card.id}
            title={card.title}
            description={card.content} 
          />
        ))}
    </div>

   
<div className='w-full pb-20'>
<AdsSlider images={ Adsimages}/>
</div>

<div className="relative w-full py-48 px-20 text-center">
      <div
        ref={ref}
        className={`typing-wrapper ${inView ? 'typing' : ''}`}
      >
        <span className="typing-demo">
         Get ready! Unique products are arriving soon.
        </span>
      </div>
    </div>



    {/* <div className="flex space-y-4 p-20 w-full">
            <VerticalCarousel />
            <VerticalCarousel />
            <VerticalCarousel />
            <VerticalCarousel />
        </div> */}
  

        {/* <div className="space-y-4 p-20 w-full">
    <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        arrows={false}
        infinite={true}
        partialVisible={false}
       
      >
        {ProductCards.map((productcard) => {
          return (
           
            <ProductCard
             key={productcard.id}
             image={productcard.image} 
             title={productcard.title}
             price={productcard.price}
             content={productcard.content}
             id={productcard.id}
             />
          );
        })}
      </Carousel>

      <div style={{ transform: 'scaleX(-1)' }}>
  <Carousel
    responsive={responsive}
    autoPlay={true}
    swipeable={true}
    draggable={true}
    arrows={false}
    infinite={true}
    partialVisible={false}
  >
    {ProductCards.map((productcard) => {
      return (
        <div key={productcard.id} style={{ transform: 'scaleX(-1)' }}> 
          <ProductCard
            image={productcard.image} 
            title={productcard.title}
            price={productcard.price}
            content={productcard.content}
            id={productcard.id}
          />
        </div>
      );
    })}
  </Carousel>
</div>




<Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        arrows={false}
        infinite={true}
        partialVisible={false}
       
      >
        {ProductCards.map((productcard) => {
          return (
            <ProductCard
             key={productcard.id}
             image={productcard.image} 
             title={productcard.title}
             price={productcard.price}
             content={productcard.content}
             id={productcard.id}
             />
          );
        })}
      </Carousel>



      <div style={{ transform: 'scaleX(-1)' }}>
  <Carousel
    responsive={responsive}
    autoPlay={true}
    swipeable={true}
    draggable={true}
    arrows={false}
    infinite={true}
    partialVisible={false}
  >
    {ProductCards.map((productcard) => {
      return (
        <div key={productcard.id} style={{ transform: 'scaleX(-1)' }}> 
          <ProductCard
            image={productcard.image} 
            title={productcard.title}
            price={productcard.price}
            content={productcard.content}
            id={productcard.id}
          />
        </div>
      );
    })}
  </Carousel>
</div>
</div> */}

    {/* <AdsSlider images={ Adsimages}/> */}
    
        
        {/* <div className='Vedio-container'>
          <video className="full-height-video" playsinline autoPlay loop muted>
          <source src={ProductVedio} type="video/mp4" width={100} height={100}/>
        </video>
        </div>
        <hr style={{ width: '60%', textAlign: 'left', marginTop: '1em' }} />
   
      <div className='Products-part2'>
        {cards.map((card) => (
          <Card
            key={card.id}
            title={card.title}
            description={card.content} 
          />
        ))}
      </div>
      <div className='Products-part3'>
       
        <AdsSlider images={ Adsimages}/>
      </div>
      <div className='Products-part4'>
<div className='VerticalProductCardContainer'>
          {columnCards.map((cardsInColumn, columnIndex) => (
            <div key={columnIndex} className='ProductCardColumn'>
              <motion.div
                className='ProductCardContainerVertical'
                {...createScrollAnimation(columnIndex % 2 === 1)}
              >
                {cardsInColumn.map((card) => (
                  <div key={card.id} className='VerticalCardContent'>
                    {card.images.map((product) => (
                       <ProductCard
                       key={product.id}
                       id={product.id}
                       image={product.image}
                       title={`Product ${product.id}`}
                       price={product.price}
                       content={product.content}
                     />
                    ))}
                  </div>
                ))}
              </motion.div>
            </div>
          ))}
        </div> 


<div className='HorizontalProductCardContainer'>
  {Array.from({ length: 5 }).map((_, rowIndex) => (
    <div key={rowIndex} className='RowContainer'>
      <motion.div
        className='ProductCardRow'
        {...horizontalcreateScrollAnimation(rowIndex % 2 === 0)} 
      >
        {Array.from({ length: 7 }).map((_, cardIndex) => {
          const cardIndexInArray = (rowIndex * 7 + cardIndex) % horizontalproductCards.length;
          const product = horizontalproductCards[cardIndexInArray];
          return (
            <ProductCard
              key={product.id}
              id={product.id}
              image={product.image}
              title={product.title}
              price={product.price}
              content={product.content}
            />
          );
        })}
      </motion.div>
    </div>
  ))}
</div>


      </div> */}
    </div>
  );
}

export default Products;
