// import React from 'react';
// import './ServiceNetwork.css';
// import styled,{keyframes,css} from 'styled-components';




// const row1=[
//   { text: '25 SEM / EDS systems' },
//   { text: '45 Tensile Testers' },
//   { text: '4 Acoustic Chambers' },
//   { text: '18 EMI Testing System' },
//   { text: '4 microCT systems' },
//   { text: '125 Environment Chamber' },
//   { text: '14 EMC Systems' },
// ]
// const row2=[
//   { text: '30+ 3D Scanning Solutions' },
//   { text: '120 Designers' },
//   { text: '20+ Prototyping Methods' },
//   { text: '14+ 3D Printing Technologies' },
//   { text: '40+ Countries' },
//   { text: '12+ Countries' },
//   { text: '250+ Warehouse' },
// ]

// function ServiceNetwork() {

//   return  <ServiceContainer> 
//     <Wrapper>
//         <Text>Service Network</Text>
//         <Marquee>
//           <MarqueeGroup>
//             {row1.map(el => (
//                 <ImageGroup>
//                     <Image><p>{el.text}</p></Image>
//                 </ImageGroup>
//             ))
//           }
//           </MarqueeGroup>
//            <MarqueeGroup>
//             {row1.map(el => (
//                 <ImageGroup>
//                     <Image><p>{el.text}</p></Image>
//                 </ImageGroup>
//             ))
//           }
//           </MarqueeGroup>
//         </Marquee>


//           <Marquee>
//              <MarqueeGroup2>
//             {row2.map(el => (
//                 <ImageGroup>
//                     <Image><p>{el.text}</p></Image>
//                 </ImageGroup>
//             ))
//           }
//           </MarqueeGroup2>
//            <MarqueeGroup2>
//             {row2.map(el => (
//                 <ImageGroup>
//                     <Image><p>{el.text}</p></Image>
//                 </ImageGroup>
//             ))
//           }
//           </MarqueeGroup2>
//           </Marquee>
//     </Wrapper>
//      </ServiceContainer>
// }

// export default ServiceNetwork;




// const ServiceContainer = styled.div`
// width:100%;
// height:500px;
// position:relative;
// color:#000000;
// display:flex;
// align-items:center;
// justify-content:center;
// `;

// const Wrapper = styled.div`
// width:100%;
// height:fit-cotent;
// display:flex;
// align-items:center;
// justify-content:center;
// flex-direction:column;
// text-align:center;
// `;


// const Text = styled.div`
// font-size:35px;
// font-weight:500;
// color:#02203c;
// text-align:center;
// margin-bottom: 2.4em;
// `;


// const Marquee = styled.div`

// display:flex;
// width:1200px;
// user-select:none;
// overflow:hidden;
// mask-image:linear-gradient(to right, 
// hsl(0 0% 0% / 0),
// hsl(0 0% 0% / 1) 10%,
// hsl(0 0% 0% / 1) 90%,
// hsl(0 0% 0% / 0))
// `;


// const scrollX = keyframes`
// from{
// left:translateX(0);
// }
// to{
// transform:translateX(-100%);
// }
// `;


// const common = css`
// flex-shrink:0;
// display:flex;
// align-items:center;
// justify-content:space-around;
// white-space:no-wrap;
// width:100%;
// animation:${scrollX} 20s linear infinite;
// `;


// const MarqueeGroup = styled.div`
// ${common}
// `;


// const MarqueeGroup2 = styled.div`
// ${common}
// animation-direction:reverse;
// animation-delay:3s;
// `;

// const ImageGroup = styled.div`
// display:grid;
// place-items:center;
// width:clamp(10rem, 1rem + 40vmin, 30rem );
// padding:calc(clamp(10rem, 0.5rem + 30vmin, 30rem )/10);
// `;

// const Image = styled.p`
// text-align:center;
// display:flex;
// align-items:center;
// justify-content:center;
// width:8.5em;
// height:5em;
// border-radius:0.5rem;
// aspect-ratio:16/9;
// white-space:wrap;
// padding:5px 20px;
// box-shadow:rgba(99,99,99,0.2) 0px 2px 8px 0px;
// `;



import React from 'react';
import './ServiceNetwork.css';
import styled, { keyframes, css } from 'styled-components';

const colors = [
  "#FF7F50", // Coral
  "#40E0D0", // Turquoise
  "#FFD700", // Gold
  "#DC143C", // Crimson
  "#32CD32", // Lime Green
  "#FF00FF", // Magenta
  "#FFDAB9", // Peach
  "#E6E6FA", // Lavender
  "#7FFF00", // Chartreuse
  "#FF69B4", // Hot Pink
];

// Function to shuffle the colors array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const shuffledColors = shuffleArray([...colors]);

const row1 = [
  { text: '25 SEM / EDS systems' },
  { text: '45 Tensile Testers' },
  { text: '4 Acoustic Chambers' },
  { text: '18 EMI Testing Systems' },
  { text: '4 microCT systems' },
  { text: '125 Environment Chamber' },
  { text: '14 EMC Systems' },
];
const row2 = [
  { text: '30+ 3D Scanning Solutions' },
  { text: '120 Designers' },
  { text: '20+ Prototyping Methods' },
  { text: '14+ 3D Printing Technologies' },
  { text: '40+ Countries' },
  { text: '12+ Countries' },
  { text: '250+ Warehouses' },
];

function ServiceNetwork() {
  return (
    <ServiceContainer>
      <Wrapper>
        <h1 className='text-4xl font-medium'>Service Network</h1>
        <Marquee>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <ImageGroup key={index}>
                <Image >
                  <p>{el.text}</p>
                </Image>
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <ImageGroup key={index}>
                <Image >
                  <p>{el.text}</p>
                </Image>
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>

        <Marquee>
          <MarqueeGroup2>
            {row2.map((el, index) => (
              <ImageGroup key={index}>
                <Image >
                  <p>{el.text}</p>
                </Image>
              </ImageGroup>
            ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
            {row2.map((el, index) => (
              <ImageGroup key={index}>
                <Image >
                  <p>{el.text}</p>
                </Image>
              </ImageGroup>
            ))}
          </MarqueeGroup2>
        </Marquee>
      </Wrapper>
    </ServiceContainer>
  );
}

export default ServiceNetwork;

const ServiceContainer = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

// const Text = styled.div`
//   font-size: larger;
//   font-weight: 500;
//   color: #02203c;
//   text-align: center;
//   margin-bottom: 2.4em;
// `;

const Marquee = styled.div`
  display: flex;
  width: 1200px;
  user-select: none;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
from {
  transform: translateX(0);
}
to {
  transform: translateX(-100%);
}
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 20s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;

const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: 3s;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(10rem, 15rem + 10vmin, 20rem);
  padding: calc(clamp(10rem, 0.5rem + 30vmin, 30rem) / 15);
`;

const Image = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  white-space: nowrap; /* Prevent text from wrapping */
  padding: 10px 20px;
  font-size: clamp(0.8rem, 1rem + 1vw, 1.2rem); /* Responsive font size */
  letter-spacing: 0.05em; /* Adds spacing between letters to prevent collision */
`;
