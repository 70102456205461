// src/components/AccordionItem.js
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './AccordionItem.css'; // Ensure you have appropriate styles
import Heading from '../Headings/Heading';

const AccordionItem = ({ title, content, isOpen, onToggle }) => {
  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={onToggle}>
      <span>{isOpen ? '−' : '+'}</span>
        <Heading as="h3">{title}</Heading>
       
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="accordion-content"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ul>
              {content.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AccordionItem;
