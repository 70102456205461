// import React, { useEffect } from 'react'
// import './Capabilities.css'
// import { motion , useAnimation} from 'framer-motion'
// import { useInView } from "react-intersection-observer";
// import Heading from './Headings/Heading';




// function Capabilities() {
//   const controls = useAnimation();
//   const { ref, inView } = useInView({
//     triggerOnce: true, 
//     threshold: 0.2, 
//   });

//   useEffect(() => {
//     console.log("Element is in view: ", inView)
//   }, [inView])

//   useEffect(() => {
//     if (inView) {
//       controls.start('visible');
//     }
//   }, [controls, inView]);

//   const fadeInLeft = {
//     hidden: { opacity: 0, x: -20 },
//     visible: { opacity: 1, x: 0, transition: { duration: 1, delay: 0.2 } }
//   };

//   const fadeInUp = (delay = 0) => ({
//     hidden: { opacity: 0, y: 20 },
//     visible: { opacity: 1, y: 0, transition: { duration: 1, delay } }
//   });

//   return (
//     <div className='capability-container' ref={ref}>
//       <motion.div
//         className='left-container'
//         variants={fadeInLeft}
//         initial="hidden"
//         animate={controls}
//       >
//        <motion.h1  variants={fadeInUp(0.4)}><Heading className='text-4xl'> Our Capabilities</Heading></motion.h1><br/>
//        <motion.p variants={fadeInUp(0.7)}>
//   {`Coveten Technologies, a leading provider of manufacturing solutions, 
//   bringing global standards to India, optimizing the production process, 
//   and increasing output capacity to satisfy demands throughout the globe. 
//   We can assist you with any task, including designing, testing, and manufacturing. 
//   Our software solutions handle every task, making it simple for you to focus and appreciate.`}
// </motion.p>
//       </motion.div>

//       <div className='right-container'>
//         <motion.div
//           className='capability-box'
//           variants={fadeInUp(0.6)}
//           initial="hidden"
//           animate={controls}
//           id='capability-box1'
//         >
//           <p>Industrial Testing & Quality Solutions</p>
//         </motion.div>
//         <motion.div
//           className='capability-box'
//           variants={fadeInUp(0.8)}
//           initial="hidden"
//           animate={controls}
//            id='capability-box2'
//         >
//           <p>Procurement Solutions</p>
//         </motion.div>
//         <motion.div
//           className='capability-box'
//           variants={fadeInUp(1.0)}
//           initial="hidden"
//           animate={controls}
//            id='capability-box3'
//         >
//           <p>Software Solutions</p>
//         </motion.div>
//       </div>
//     </div>
//   )
// }

// export default Capabilities;









import React, { useState, useEffect } from 'react';
import './Capabilities.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Heading from './Headings/Heading';

function Capabilities() {
  const [hoveredBox, setHoveredBox] = useState(null);
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const fadeInLeft = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, delay: 0.2 } },
  };

  const fadeInUp = (delay = 0) => ({
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, delay } },
  });





  const contentMap = {
    'Industrial Testing & Quality Solutions': "We offer testing services for all manufacturing industries, including consumer products, toys, electronics, automotive, batteries up to aviation, and regardless the stage of manufacturing/product.",
    'Procurement Solutions': "You may use well-maintained manufacturing facilities, excellent warehousing, integratedlogistics, and approved raw materials, well crafted testing labs all in one place.",
    'Software Solutions': "We do offer top-notch IOT and any other softwares at reasonable costs that meets your needs,Our tailored solutions will undoubtedly assist you in meeting all of your goals.",
  };


  

  const handleMouseEnter = (key) => {
    setHoveredBox(key);
  };

  const handleMouseLeave = () => {
    setHoveredBox(null);
  };

  return (
    <div className='capability-container' ref={ref}>
      <motion.div
        className='left-container p-7'
        variants={fadeInLeft}
        initial="hidden"
        animate={controls}
      >
        <motion.h1 variants={fadeInUp(0.4)}>
         <h1 className='text-4xl font-medium'>
            {hoveredBox || 'Our Capabilities'}
            </h1>
        </motion.h1>
        <br />
        <motion.p>
          {hoveredBox
            ? contentMap[hoveredBox]
            : `Coveten Technologies, a leading provider of manufacturing solutions, bringing global standards to India, optimizing the production process, and increasing output capacity to satisfy demands throughout the globe. We can assist you with any task, including designing, testing, and manufacturing. Our software solutions handle every task, making it simple for you to focus and appreciate.`
          }
        </motion.p>
      </motion.div>

      <div className='right-container'>
        {Object.keys(contentMap).map((key) => (
          <motion.div
            key={key}
            className='capability-box'
            variants={fadeInUp(0.6)}
            initial="hidden"
            animate={controls}
            onMouseEnter={() => handleMouseEnter(key)}
            onMouseLeave={handleMouseLeave}
          >
            <p>{key}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Capabilities;
