// import React from 'react';
// import './BenefitPageContent.css';
// import HealthImg from '../../assets/Health.jpg';
// import NewsContent from './NewsContent';

// function BenefitPageContent() {
//   const articles = [
//     {
//       image: HealthImg,
//       altText: "emc3",
//       heading: "Award-winning events agency, emc3, acquires sustainability consultancy ‘from now’",
//       date: "15th August 2024"
//     },
//     {
//       image: HealthImg,
//       altText: "Event 100 Club",
//       heading: "Industry leaders to launch the Event 100 Club",
//       date: "14th August 2024"
//     },
//     {
//       image: HealthImg,
//       altText: "RHC",
//       heading: "Rhubarb Hospitality Collection (RHC) acquires historic London event space, The Brewery",
//       date: "12th August 2024"
//     },
//     {
//       image: HealthImg,
//       altText: "Bray Leino",
//       heading: "Bray Leino Events wins UK Government tender to deliver Expo 2025 Osaka",
//       date: "7th August 2024"
//     }
//   ];
  




//   return (
//     <div className="w-full h-auto p-10">
//       {/* First row with 3 larger items */}
//       <div className="grid grid-cols-3 gap-4 mb-4">
//         <div
//           className="relative bg-cover bg-center p-8 col-span-1 flex flex-col justify-end h-[220px]"
//           style={{ backgroundImage: `url(${HealthImg})` }}
//         >
//           <div className="flex flex-col items-start text-start">
//             <span className="text-white text-[20px] leading-[26px] font-bold">
//               Lorem Ipsum
//             </span>
//             <time className="text-white text-xs mt-2" dateTime="2024-08-20T13:44:37+01:00">
//               20th August 2024
//             </time>
//           </div>
//         </div>
//         <div
//           className="relative bg-cover bg-center p-8 col-span-1 flex flex-col justify-end h-[220px]"
//           style={{ backgroundImage: `url(${HealthImg})` }}
//         >
//           <div className="flex flex-col items-start text-start">
//             <span className="text-white text-[20px] leading-[26px] font-bold">
//               Lorem Ipsum
//             </span>
//             <time className="text-white text-xs mt-2" dateTime="2024-08-20T13:44:37+01:00">
//               20th August 2024
//             </time>
//           </div>
//         </div>
//         <div
//           className="relative bg-cover bg-center p-8 col-span-1 flex flex-col justify-end h-[220px]"
//           style={{ backgroundImage: `url(${HealthImg})` }}
//         >
//           <div className="flex flex-col items-start text-start">
//             <span className="text-white text-[20px] leading-[26px] font-bold">
//               Lorem Ipsum
//             </span>
//             <time className="text-white text-xs mt-2" dateTime="2024-08-20T13:44:37+01:00">
//               20th August 2024
//             </time>
//           </div>
//         </div>
//       </div>

//       {/* Second row with 4 smaller items */}
//       <div className="grid grid-cols-4 gap-4">
//         <div
//           className="relative bg-cover bg-center p-4 col-span-1 flex flex-col justify-end h-[175px]"
//           style={{ backgroundImage: `url(${HealthImg})` }}
//         >
//           <span className="text-white text-[16px] leading-[21px] font-medium">
//           Lorem Ipsum
//           </span>
//         </div>
//         <div
//           className="relative bg-cover bg-center p-4 col-span-1 flex flex-col justify-end h-[175px]"
//           style={{ backgroundImage: `url(${HealthImg})` }}
//         >
//           <span className="text-white text-[16px] leading-[21px] font-medium">
//           Lorem Ipsum
//           </span>
//         </div>
//         <div
//           className="relative bg-cover bg-center p-4 col-span-1 flex flex-col justify-end h-[175px]"
//           style={{ backgroundImage: `url(${HealthImg})` }}
//         >
//           <span className="text-white text-[16px] leading-[21px] font-medium">
//           Lorem Ipsum
//           </span>
//         </div>
//         <div
//           className="relative bg-cover bg-center p-4 col-span-1 flex flex-col justify-end h-[175px]"
//           style={{ backgroundImage: `url(${HealthImg})` }}
//         >
//           <span className="text-white text-[16px] leading-[21px] font-medium">
//           Lorem Ipsum
//           </span>
//         </div>
//       </div>

      
// <NewsContent 
//   title="BRAND & AGENCY NEWS"
//   sectionHeader="BRAND & AGENCIES"
//   mainImage={HealthImg}
//   mainHeading="Venues of Excellence announces new partnership with Safe Hands Counselling"
//   mainDate="15th August 2024"
//   mainDescription="Venues of Excellence (VOE), the UK’s leading sales and marketing consortium, 
//                   is committed to partner with services and products who will provide a benefit..."
//   articles={articles}
// />



//       <NewsContent
//         title="Conference & Meetings News"
//         sectionHeader="Conference News"
//         mainImage={HealthImg}
//         mainHeading="Kempton Park Racecourse – Elevate Your Events with Unmatched Venues and Catering"
//         mainDate="15th August 2024"
//         mainDescription="Venues of Excellence (VOE), the UK’s leading sales and marketing consortium, 
//                       is committed to partner with services and products who will provide a benefit..."
//         articles={articles}
//       />

//     </div>
//   );
// }

// export default BenefitPageContent;








import React from 'react';
import './BenefitPageContent.css';
import HealthImg from '../../assets/images/popular/pop17.jpg';
import FoodImg from '../../assets/images/hero/hero1.jpg';
import SafetyGadgetsImg from '../../assets/images/hero/hero2.jpg'
import SmartGadgetsImg from '../../assets/images/hero/hero3.jpg'
import TravelImg1 from '../../assets/images/popular/pop2.jpg'
import FitnessImg1 from '../../assets/images/popular/pop18.jpg'
import FitnessImg2 from '../../assets/images/popular/pop23.jpg'
import FitnessImg3 from '../../assets/images/popular/pop24.jpg'
import FitnessImg4 from '../../assets/images/popular/pop25.jpg'
import SportsImg1 from '../../assets/images/popular/pop19.jpg'
import SportsImg2 from '../../assets/images/popular/pop30.jpg'
import SportsImg3 from '../../assets/images/popular/pop27.jpg'
import SportsImg4 from '../../assets/images/popular/pop28.jpg'
import SportsImg5 from '../../assets/images/popular/pop29.jpg'
import network1 from '../../assets/- Our upcoming networking events & meetings/-Industry-InsightsGain-access-to-c.jpeg'
import network2 from '../../assets/- Our upcoming networking events & meetings/Collaborate for Innovation.jpg'
import network3 from '../../assets/- Our upcoming networking events & meetings/Expand influ.png'
import network4 from '../../assets/- Our upcoming networking events & meetings/Powerfull connection.png'
import network5 from '../../assets/- Our upcoming networking events & meetings/Stay Ahead of the Curve.jpg'
import sp1 from '../../assets/- Service providers/Buy Best Instruments at Good Prices in Our B2B.jpg'
import sp2 from '../../assets/- Service providers/International standards.png'
import sp3 from '../../assets/- Service providers/More Opportunities.jpg'
import sp4 from '../../assets/- Service providers/We-got-better-Access-to-Manufacturers.jpeg'
import sp5 from '../../assets/- Service providers/marketing magic.jpg'
import d1 from '../../assets/Dealer & Distrubutors/Better grooming sessions.jpg'
import d2 from '../../assets/Dealer & Distrubutors/Sell and buy.jpg'
import d3 from '../../assets/Dealer & Distrubutors/Unlocking Top Strategies for Dealerships.jpg'
import d4 from '../../assets/Dealer & Distrubutors/Work at Your Own Comfort.jpg'
import d5 from '../../assets/Dealer & Distrubutors/2aa.jpg'
import g1 from '../../assets/General/Boost Awareness.jpg'
import g2 from '../../assets/General/Fair b2b.png'
import g3 from '../../assets/General/No more lobbying.png'
import g4 from '../../assets/General/hospital .png'
import g5 from '../../assets/General/trace .png'
import m1 from '../../assets/Manufact/1.jpg'
import m2 from '../../assets/Manufact/2.jpg'
import m3 from '../../assets/Manufact/3.jpg'
import m4 from '../../assets/Manufact/4.jpg'
import m5 from '../../assets/Manufact/5.jpg'
import Tech1 from '../../assets/General/2024-08-30.png'
import TechnologyImg1 from '../../assets/images/popular/pop41.jpg'
import TechnologyImg2 from '../../assets/images/popular/pop42.jpg'
import TechnologyImg3 from '../../assets/images/popular/pop43.jpg'
import TechnologyImg4 from '../../assets/images/popular/pop44.jpg'
import Tech5 from '../../assets/images/popular/technology.png'
import BusinessImg1 from '../../assets/images/popular/pop36.jpg'
import BusinessImg2 from '../../assets/images/popular/pop37.jpg'
import BusinessImg3 from '../../assets/images/popular/pop38.jpg'
import BusinessImg4 from '../../assets/images/popular/pop39.jpg'
import BusinessImg5 from '../../assets/images/popular/pop40.jpg'
import NewsContent from './NewsContent';
import AdvertisementSection from './AdvertisementSection';
import NewsTicker from './NewsTicker';

function BenefitPageContent() {


  const largehero = [
    { imgSrc: FoodImg, title: "Our FOOD CARDS", date: "20th August 2024" },
    { imgSrc: SafetyGadgetsImg, title: "Our SAFETY GADGETS", date: "20th August 2024" },
    { imgSrc: SmartGadgetsImg, title: "Our SMART GADGETS", date: "20th August 2024" },
  ];
  
  const smallhero = [
    { imgSrc: TravelImg1, title: "Travel"},
    { imgSrc: HealthImg, title: "Health" },
    { imgSrc: FitnessImg1, title: "Fitness" },
    { imgSrc: SportsImg1, title: "Sports" },
  ];


  const newsItems = [
    "Being our partners will give you more",
    "Enjoy greater discounts on your travel, food and health packages",
    "Buying electronics for your home or office is now more exciting with our integrated partner offers"
  ];

  const Travelarticles = [
    {
      image: m2,
      altText: "emc3",
      heading: "100% Trusted Deals for Manufacturers!",
      date: "15th August 2024"
    },
    {
      image: m3,
      altText: "Event 100 Club",
      heading: "Introducing Extended Warranty Options!",
      date: "14th August 2024"
    },
    {
      image: m4,
      altText: "RHC",
      heading: "Unlock a World of End-to-End Testing Support",
      date: "12th August 2024"
    },
    {
      image: m5,
      altText: "Bray Leino",
      heading: "Support width design",
      date: "7th August 2024"
    }
  ];




  const Healtharticles = [
    {
      image: d2,
      altText: "emc3",
      heading: "We empower dealers and local networks with the tools and insights.",
      date: "15th August 2024"
    },
    {
      image: d3,
      altText: "Event 100 Club",
      heading: "We offer dealers and local networks a variety of options to sell and buy products seamlessly.",
      date: "14th August 2024"
    },
    {
      image: d4,
      altText: "RHC",
      heading: "Work at Your Own Comfort with Coveten Technologies!",
      date: "12th August 2024"
    },
    {
      image: d5,
      altText: "Bray Leino",
      heading: "Better grooming sessions",
      date: "7th August 2024"
    }
  ];





  const Fitnessarticles = [
    {
      image: sp2,
      altText: "emc3",
      heading: "Empowering Testing Laboratories with More Opportunities!",
      date: "15th August 2024"
    },
    {
      image: sp3,
      altText: "Event 100 Club",
      heading: "Unlock Your Lab's Potential - Buy Best Instruments at Good Prices in Our B2B",
      date: "14th August 2024"
    },
    {
      image: sp4,
      altText: "RHC",
      heading: "Transform Your Labs Qualify for International Standards",
      date: "12th August 2024"
    },
    {
      image: sp5,
      altText: "Bray Leino",
      heading: "We got better Access to Manufacturers with Coveten Technologies",
      date: "7th August 2024"
    }
  ];



  const Sportsarticles = [
    {
      image: network2,
      altText: "emc3",
      heading: "Forge Powerful Connections- Build valuable relationships with professionals and experts who can help accelerate your business growth.",
      date: "15th August 2024"
    },
    {
      image: network3,
      altText: "Event 100 Club",
      heading: "Expand Your Influence-Showcase your expertise and position yourself as a thought leader within your industry by joining our dynamic community.",
      date: "14th August 2024"
    },
    {
      image: network4,
      altText: "RHC",
      heading: "Collaborate for innovation",
      date: "12th August 2024"
    },
    {
      image: network5,
      altText: "Bray Leino",
      heading: "Stay Ahead of the Curve- Keep your business competitive by staying informed and inspired through our tailored events and meetings. ",
      date: "7th August 2024"
    }
  ];


  const Entertainmentarticles = [
    {
      image: g2,
      altText: "emc3",
      heading: "Fair B2B Deals for all the partners",
      date: "15th August 2024"
    },
    {
      image:g3,
      altText: "Event 100 Club",
      heading: "No more lobbying",
      date: "14th August 2024"
    },
    {
      image: g4,
      altText: "RHC",
      heading: "Trace and track every activity with us",
      date: "12th August 2024"
    },
    {
      image: g5,
      altText: "Bray Leino",
      heading: " Hospitals can buy capital good on B2B prices",
      date: "7th August 2024"
    }
  ];



  const Technologyarticles = [
    {
      image: TechnologyImg1,
      altText: "emc3",
      heading: "Unqiue Technology",
      date: "15th August 2024"
    },
    {
      image: TechnologyImg2,
      altText: "Event 100 Club",
      heading: "Top 2024 Technologies",
      date: "14th August 2024"
    },
    {
      image: TechnologyImg3,
      altText: "RHC",
      heading: "Idea into Reality ",
      date: "12th August 2024"
    },
    {
      image: Tech5,
      altText: "Bray Leino",
      heading: "Stimulate creativity",
      date: "7th August 2024"
    }
  ];


  const Businessarticles = [
    {
      image: BusinessImg1,
      altText: "emc3",
      heading: "Elevate Your Business with Expert Consulting Services ",
      date: "15th August 2024"
    },
    {
      image: BusinessImg2,
      altText: "Event 100 Club",
      heading: "Specialised Marketing (B2B)",
      date: "14th August 2024"
    },
    {
      image: BusinessImg3,
      altText: "RHC",
      heading: "Strategic planning ",
      date: "12th August 2024"
    },
    {
      image: BusinessImg4,
      altText: "Bray Leino",
      heading: "Enable to setup your Startup",
      date: "7th August 2024"
    }
  ];








  return (
    <div className="w-full h-auto p-10">
      <NewsTicker newsItems={newsItems} />
      {/* First row with 3 larger items */}
      <div className="grid grid-cols-3 gap-4 mb-4">
      {largehero.map((item, index) => (
        <div className="image-wrap" key={index}>
          <img src={item.imgSrc} alt={item.title} />
          <div className="text-content flex flex-col">
            <span className="text-white text-[20px] leading-[26px] font-bold">
              {item.title}
            </span>
            <time className="text-white text-xs mt-2" dateTime="2024-08-20T13:44:37+01:00">
              {item.date}
            </time>
          </div>
        </div>
      ))}
    </div>

      {/* Second row with 4 smaller items */}
      <div className="grid grid-cols-4 gap-4">
      {smallhero.map((item, index) => (
        <div className="image-wrap" key={index}>
          <img src={item.imgSrc} alt={item.title}/>
          <div className="text-content">
            <span className="text-white text-[16px] leading-[21px] font-medium">
              {item.title}
            </span>
          </div>
        </div>
      ))}
    </div>

      {/* Container for NewsContent and AdvertisementSection */}
      <div className="flex flex-row items-start w-full mt-24">
        <div className="w-full flex flex-col items-center justify-between gap-12">
          <NewsContent
            title="Manufacturing Partners"
            sectionHeader="Manufacturing Partners"
            mainImage={m1}
            mainHeading="Optimize Marketing your business with Coveten Technologies"
            mainDate="15th August 2024"
            mainDescription="Something extraordinary is on the horizon!"
            articles={Travelarticles}
          />

          <NewsContent
            title="Dealer & Distrubutors"
            sectionHeader="Dealer & Distrubutors"
            mainImage={d1}
            mainHeading="Unlocking Top Strategies for Dealerships"
            mainDate="15th August 2024"
            mainDescription="Something extraordinary is on the horizon!"
            articles={Healtharticles}
          />

          <NewsContent
            title="Service providers"
            sectionHeader="Service providers"
            mainImage={sp1}
            mainHeading="Let’s make marketing magic happen together."
            mainDate="15th August 2024"
            mainDescription="Something extraordinary is on the horizon!"
            articles={Fitnessarticles}
          />

          <NewsContent
            title="Our upcoming networking events & meetings"
            sectionHeader="Our upcoming networking events & meetings"
            mainImage={network1}
            mainHeading="Unlock Exclusive Industry Insights"
            mainDate="15th August 2024"
            mainDescription="Gain access to cutting-edge knowledge and trends from industry leaders at our networking events."
            articles={Sportsarticles}
          />

          <NewsContent
            title="General"
            sectionHeader="General"
            mainImage={g1}
            mainHeading="Boost More Awareness with Coveten Technologies"
            mainDate="15th August 2024"
            mainDescription="Something extraordinary is on the horizon!"
            articles={Entertainmentarticles}
          />
          <NewsContent
            title="TECHNOLOGY"
            sectionHeader="TECHNOLOGY"
            mainImage={Tech1}
            mainHeading="Being out trusted Technologies B2B member."
            mainDate="15th August 2024"
            mainDescription="Something extraordinary is on the horizon!"
            articles={Technologyarticles}
          />
          <NewsContent
            title="BUSINESS"
            sectionHeader="BUSINESS"
            mainImage={BusinessImg5}
            mainHeading="Elevate Your Business with Expert Consulting Services "
            mainDate="15th August 2024"
            mainDescription="Something extraordinary is on the horizon!"
            articles={Businessarticles}
          />

        </div>

        <div className="w-1/2">
          <AdvertisementSection />
        </div>
      </div>
    </div>
  );
}

export default BenefitPageContent;
