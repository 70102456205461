import React, { useEffect } from 'react';
import './ServiceNetworkGlobe.css'; // Import the CSS file
import TagCloud from "https://cdn.skypack.dev/TagCloud@2.3.2";
import Heading from '../Headings/Heading';
import NetworkAnimation from './NetworkAnimation';

function ServiceNetworkGlobe() {
  useEffect(() => {
    const container = ".tagcloud-container";
    const texts = [
      "25 SEM / EDS systems",
      "45 Tensile Testers",
      "4 Acoustic Chambers",
      "18 EMI Testing Systems",
      "4 microCT systems",
      "125 Environment Chambers",
      "14 EMC Systems",
      "30+ 3D Scanning Solutions",
      "120 Designers",
      "20+ Prototyping Methods",
      "14+ 3D Printing Technologies",
      "40+ Countries",
      "12+ Countries",
      "250+ Warehouses",
      "25 SEM / EDS systems",
      "45 Tensile Testers",
      "4 Acoustic Chambers",
      "18 EMI Testing Systems",
      "4 microCT systems",
      "125 Environment Chambers",
      "14 EMC Systems",
      "30+ 3D Scanning Solutions",
      "120 Designers",
      "20+ Prototyping Methods"
    ];
    const options = {
      containerClass: "tag-cloud",
      itemClass: "tag",
      radius: 350,
      direction: 225,
      initSpeed: "fast",
      maxSpeed: "normal"
    };
    
    TagCloud(container, texts, options);
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className='w-full flex flex-row p-20 items-center justify-between'>
     <h3 className='text-black relative text-4xl heading-blueunderline'>Our Service Network</h3>
      {/* <NetworkAnimation/> */}
    <div className='tagcloud-container '>
      {/* TagCloud will be initialized here */}
    </div>
    </div>
  );
}

export default ServiceNetworkGlobe;
